import { ArrowDownward, ArrowUpward, ClearAll } from "@mui/icons-material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { CampusService } from "../../../services/CampusService";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import AddCampus from "./AddCampus";
import CustomFields from "./CustomFields";
const Campus = () => {
    const [filter, setFilter] = useState<any>({});
    const [changes, setChanges] = useState(0);
    const { user } = useContext(AppContext);

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({ search: "" });
    };

    const ChangeCampusSequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await CampusService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton onClick={() => handleLevelChange("up")}>
                    <Tooltip title="Move up">
                        <ArrowUpward />
                    </Tooltip>
                </IconButton>

                <IconButton onClick={() => handleLevelChange("down")}>
                    <Tooltip title="Move down">
                        <ArrowDownward />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Campus
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE CAMPUS"]}>
                        <AddView
                            label="add campus"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddCampus />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid item container spacing={2}>
                    <Grid item xs={10}>
                        {" "}
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    CampusService.getCampus(page, limit, filter)
                }
                deletor={
                    user?.baseUser?.ops.includes("DELETE CAMPUS")
                        ? (id: string | number) =>
                              CampusService.deleteCampusSoft(id)
                        : undefined
                }
                // (id: string | number) => CampusService.deleteCampusSoft(id)
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    latitude: row.latitude ? (
                        row.latitude
                    ) : (
                        <HorizontalRuleIcon />
                    ),

                    longitude: row.longitude ? (
                        row.longitude
                    ) : (
                        <HorizontalRuleIcon />
                    ),

                    Editlatitude: row.latitude ? row.latitude : "",
                    Editlongitude: row.longitude ? row.longitude : "",
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    address: "Address",
                    startTime: "Start time",
                    endTime: "End time",
                    latitude: "latitude",
                    longitude: "Longitude",
                    AddInfo: "Add. Info",
                    Edit: "Edit",
                    Changesequence: "Change Sequence",
                    // Editlatitude: "Editlatitude",
                }}
                editables={[]}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeCampusSequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: "AddInfo",
                        content: (row) => <CustomFields row={row} />,
                    },
                    {
                        header: user?.baseUser?.ops.includes("UPDATE CAMPUS")
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE CAMPUS"]}>
                                <AddView
                                    label="Edit campus"
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <AddCampus row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter, changes]}
                ops={{
                    read: "READ CAMPUS",
                    edit: "",
                    delete: "DELETE CAMPUS",
                }}
                storageKey="campus"
            />
        </div>
    );
};

export default Campus;
