import { Box, ListItemIcon, ListItemSecondaryAction } from "@material-ui/core";
import { Add, Close, Info, Remove } from "@mui/icons-material";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Snackbar,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { APIRequestStatus, useGetAPI, usePatchAPI } from "../../services/Hooks";
import { RoleService } from "../../services/RoleService";
import { AppContext } from "../AppContext";
import { BaseBreadcrum } from "../BaseBreadcrum";
import { RUDTable } from "../Table/RUDTable";

type Props = {
    // editMode?: boolean;
    mode: "create" | "edit" | "read";
};

type Op = { id: number; name: string };

const initialSelectedCats: {
    [key: string]: {
        [key: number]: { id: number; name: string; checked: boolean };
    };
} = {};

const CreateRole = ({ mode = "create" }: Props) => {
    const location = useLocation();
    const [roleId, setRoleId] = useState("");
    const [roleName, setRoleName] = useState("");
    const [rolesDescription, setRolesDescription] = useState("");
    const [search, setSearch] = useState("");
    const { feedback, setFeedback } = useContext(AppContext);
    const [opAndCategories, setOpAndCategorieas] = useState<any[]>([]);
    const [selectedCategories, setSelectedCategories] =
        useState(initialSelectedCats);
    const theme = useTheme();

    const handleSelectCategories = (
        selected: boolean,
        row: { name: string; ops: Op[] }
    ) => {
        switch (selected) {
            case true:
                setSelectedCategories((state) =>
                    Object.fromEntries(
                        Object.entries(state).filter(
                            ([catName, v]) => catName !== row.name
                        )
                    )
                );
                return;

            case false:
                setSelectedCategories((state) => ({
                    ...state,
                    [row.name]: row.ops.reduce(
                        (prev, op) => ({
                            ...prev,
                            [op.id]: {
                                id: op.id,
                                name: op.name,
                                checked: false,
                            },
                        }),
                        {}
                    ),
                }));
                return;

            default:
                return;
        }
    };

    const handleOpsCheck = (
        e: ChangeEvent<HTMLInputElement>,
        check: boolean,
        opId: number,
        catName: string
    ) => {
        setSelectedCategories((state) => ({
            ...state,
            [catName]: {
                ...state[catName],
                [opId]: { ...state[catName][opId], checked: check },
            },
        }));
    };

    const handleCategoryCheck = (category: string, check: boolean) => {
        setSelectedCategories((state) => ({
            ...state,
            [category]: {
                ...state[category],
                ...Object.fromEntries(
                    Object.entries(selectedCategories[category]).map(
                        ([k, v]) => [k, { ...v, checked: check }]
                    )
                ),
            },
        }));
    };

    const getopAndCategorieas = async () => {
        const [data, err] = await RoleService.getRolesCategories(1, 100);

        setOpAndCategorieas(data.rows);
    };

    const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        const roleOps = Object.values(selectedCategories)
            .map((item) =>
                Object.entries(item)
                    .filter((item) => item[1].checked)
                    .map((item) => item[0])
            )
            .flat();

        const [data, err] = await RoleService.createRoleFromOps(
            roleOps,
            roleName,
            rolesDescription
        );
        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                severity: "success",
                show: true,
            });
        } else {
            setFeedback({
                loading: false,
                message: err,
                severity: "error",
                show: true,
            });
        }
    };

    const [roleToEdit, _, roleStatus, roleMessage] = useGetAPI(
        RoleService.url + "/role",
        1,
        1,
        { id: roleId },
        [mode, roleId],
        [mode, roleId],
        (rows) => rows[0] || null
    );

    const [editRole, editResponse, editStatus, editMessage] = usePatchAPI(
        RoleService.url + "/role/permissions"
    );

    useEffect(() => {
        if (roleToEdit && roleStatus === APIRequestStatus.success) {
            setRoleName(roleToEdit.name);
            setRolesDescription(roleToEdit.description);
            const _opsInRole = roleToEdit.permissions.map(
                (permission: any) => permission.op.id
            );
            let currCats: string[] = [];

            const initialOpNCats = opAndCategories.reduce(
                (prev: any, curr: any) => {
                    prev[curr.name] = curr.ops.reduce(
                        (prevOp: any, currOp: any) => {
                            if (_opsInRole.includes(currOp.id)) {
                                currCats.push(curr.name);
                            }

                            prevOp[currOp.id] = {
                                id: currOp.id,
                                name: currOp.name,
                                checked: _opsInRole.includes(currOp.id),
                            };

                            return prevOp;
                        },
                        {}
                    );

                    return prev;
                },
                {}
            );

            setSelectedCategories(
                currCats.reduce(
                    (prev: any, cat) => ({
                        ...prev,
                        [cat]: initialOpNCats[cat],
                    }),
                    {}
                )
            );
        }
    }, [roleToEdit, roleStatus, opAndCategories]);

    const handleEdit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        const roleOps = Object.values(selectedCategories)
            .map((item) =>
                Object.entries(item)
                    .filter((item) => item[1].checked)
                    .map((item) => item[0])
            )
            .flat();

        editRole(
            {
                permissions: roleOps,
                name: roleName,
                description: rolesDescription,
            },
            { id: roleId }
        );
    };

    useEffect(() => {
        getopAndCategorieas();
    }, []);

    useEffect(() => {
        if (["read", "edit"].includes(mode)) {
            const query = new URLSearchParams(location.search);
            const _roleId = query.get("role");
            setRoleId(_roleId || "");
        }
    }, [mode, location.search]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <BaseBreadcrum
                        links={[
                            {
                                linkName: "Roles",
                                linkUrl: "/role",
                            },
                            {
                                linkName:
                                    mode === "edit" ? "Edit Role" : "Add Role",
                                linkUrl:
                                    mode === "edit"
                                        ? location.pathname + location.search
                                        : "/add-role",
                            },
                        ]}
                    />
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    flex={1}
                    height={`calc(100vh - ${theme.spacing(20)})`}
                >
                    <Grid item container xs={12} spacing={1} maxHeight="100%">
                        <Grid item flex={1} height="100%">
                            <RUDTable
                                getter={(page, limit) =>
                                    RoleService.getRolesCategories(
                                        !search ? page : 1,
                                        limit,
                                        { search }
                                    )
                                }
                                readables={{
                                    id: "ID",
                                    name: "Category",
                                    ViewPermissions: "View Permissions",
                                }}
                                customCols={[
                                    {
                                        header: "ViewPermissions",
                                        content: (row) => (
                                            <IconButton
                                                disabled={mode === "read"}
                                                onClick={() =>
                                                    handleSelectCategories(
                                                        Object.keys(
                                                            selectedCategories
                                                        ).includes(row.name),
                                                        row
                                                    )
                                                }
                                            >
                                                {Object.keys(
                                                    selectedCategories
                                                ).includes(row.name) ? (
                                                    <Remove
                                                        htmlColor={
                                                            theme.palette
                                                                .primary.main
                                                        }
                                                    />
                                                ) : (
                                                    <Add />
                                                )}
                                            </IconButton>
                                        ),
                                    },
                                ]}
                                ops={{
                                    read: "READ ROLE",
                                    edit: "UPDATE ROLE",
                                    delete: "DELETE ROLE",
                                }}
                                updatingAgents={[search]}
                                // getSearchValue={(query) => setSearch(query)}
                                storageKey="rolesDetails"
                            />
                        </Grid>

                        <Grid item xs={12} md={4} height="100%">
                            <form
                                onSubmit={
                                    mode === "edit"
                                        ? handleEdit
                                        : mode === "create"
                                        ? handleSubmit
                                        : undefined
                                }
                                style={{
                                    // marginTop: theme.spacing(11),
                                    marginLeft: theme.spacing(1),
                                    marginRight: theme.spacing(1),
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <TextField
                                    fullWidth
                                    value={roleName}
                                    onChange={
                                        ["create", "edit"].includes(mode)
                                            ? (ev) =>
                                                  setRoleName(ev.target.value)
                                            : undefined
                                    }
                                    variant="outlined"
                                    label="Role name"
                                    name="name"
                                    color="primary"
                                    size="small"
                                    required
                                    disabled={mode === "read"}
                                />

                                <TextField
                                    fullWidth
                                    label="Description"
                                    name="description"
                                    variant="outlined"
                                    size="small"
                                    value={rolesDescription}
                                    sx={{ marginTop: theme.spacing(2) }}
                                    onChange={
                                        ["create", "edit"].includes(mode)
                                            ? (ev) =>
                                                  setRolesDescription(
                                                      ev.target.value
                                                  )
                                            : undefined
                                    }
                                    disabled={mode === "read"}
                                />

                                <Box
                                    mb={2}
                                    border={`1.5px solid ${theme.palette.divider}`}
                                    mt={2}
                                    borderRadius={theme.shape.borderRadius}
                                    overflow="auto"
                                    position="relative"
                                    flex={1}
                                >
                                    {Object.keys(selectedCategories).length ? (
                                        Object.entries(selectedCategories)
                                            .reverse()
                                            .map(([catName, v]) => (
                                                <List key={catName}>
                                                    <ListItem
                                                        dense
                                                        divider
                                                        sx={{
                                                            position: "sticky",
                                                            top: 0,
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                size="small"
                                                                onChange={
                                                                    mode ===
                                                                    "read"
                                                                        ? undefined
                                                                        : (
                                                                              e,
                                                                              check
                                                                          ) =>
                                                                              handleCategoryCheck(
                                                                                  catName,
                                                                                  check
                                                                              )
                                                                }
                                                                checked={
                                                                    Object.keys(
                                                                        v
                                                                    ).length ===
                                                                    Object.values(
                                                                        v
                                                                    ).filter(
                                                                        (op) =>
                                                                            op.checked
                                                                    ).length
                                                                }
                                                                indeterminate={
                                                                    Object.keys(
                                                                        v
                                                                    ).length >
                                                                        Object.values(
                                                                            v
                                                                        ).filter(
                                                                            (
                                                                                op
                                                                            ) =>
                                                                                op.checked
                                                                        )
                                                                            .length &&
                                                                    Object.values(
                                                                        v
                                                                    ).filter(
                                                                        (op) =>
                                                                            op.checked
                                                                    ).length > 0
                                                                        ? true
                                                                        : undefined
                                                                }
                                                            />
                                                        </ListItemIcon>

                                                        <ListItemText
                                                            primaryTypographyProps={{
                                                                // variant: "h6",
                                                                fontWeight:
                                                                    "bolder",
                                                                color: "primary",
                                                            }}
                                                        >
                                                            {catName}
                                                        </ListItemText>

                                                        <ListItemSecondaryAction>
                                                            <IconButton
                                                                size="small"
                                                                disabled={
                                                                    mode ===
                                                                    "read"
                                                                }
                                                                onClick={
                                                                    mode ===
                                                                    "read"
                                                                        ? undefined
                                                                        : () =>
                                                                              handleSelectCategories(
                                                                                  true,
                                                                                  {
                                                                                      name: catName,
                                                                                      ops: Object.values(
                                                                                          v
                                                                                      ),
                                                                                  }
                                                                              )
                                                                }
                                                            >
                                                                <Close fontSize="small" />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>

                                                    {Object.values(v).map(
                                                        (op) => (
                                                            <ListItem
                                                                key={op.id}
                                                                dense
                                                                divider
                                                            >
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={
                                                                            op.checked
                                                                        }
                                                                        onChange={
                                                                            mode ===
                                                                            "read"
                                                                                ? undefined
                                                                                : (
                                                                                      e,
                                                                                      check
                                                                                  ) =>
                                                                                      handleOpsCheck(
                                                                                          e,
                                                                                          check,
                                                                                          op.id,
                                                                                          catName
                                                                                      )
                                                                        }
                                                                    />
                                                                </ListItemIcon>

                                                                <ListItemText
                                                                    primaryTypographyProps={{
                                                                        sx: {
                                                                            opacity: 0.75,
                                                                        },
                                                                    }}
                                                                >
                                                                    {op.name}
                                                                </ListItemText>
                                                            </ListItem>
                                                        )
                                                    )}
                                                </List>
                                            ))
                                    ) : (
                                        <Box
                                            height="100%"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            p={2}
                                        >
                                            <Typography
                                                variant="body2"
                                                textAlign="center"
                                                color={
                                                    theme.palette.action
                                                        .disabled
                                                }
                                            >
                                                <Info />
                                                <br />
                                                Please select a category to show
                                                its permissions
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>

                                {mode === "edit" ? (
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        type="submit"
                                        disabled={
                                            editStatus ===
                                                APIRequestStatus.loading ||
                                            Object.keys(selectedCategories)
                                                .length === 0
                                        }
                                        endIcon={
                                            editStatus ===
                                            APIRequestStatus.loading ? (
                                                <CircularProgress size="1em" />
                                            ) : null
                                        }
                                    >
                                        {editStatus === APIRequestStatus.loading
                                            ? "Editing  Role..."
                                            : "Edit role"}
                                    </Button>
                                ) : mode === "create" ? (
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        type="submit"
                                        disabled={
                                            feedback.loading ||
                                            Object.keys(selectedCategories)
                                                .length === 0
                                        }
                                        endIcon={
                                            feedback.loading ? (
                                                <CircularProgress size="1em" />
                                            ) : null
                                        }
                                    >
                                        {feedback.loading
                                            ? "Adding  Role..."
                                            : "Add role"}
                                    </Button>
                                ) : null}
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Snackbar
                open={
                    (mode !== "edit" && feedback.show) ||
                    (mode === "edit" &&
                        [
                            APIRequestStatus.success,
                            APIRequestStatus.error,
                        ].includes(editStatus))
                }
                autoHideDuration={6000}
                onClose={() => setFeedback((f) => ({ ...f, show: false }))}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    severity={
                        mode !== "edit" && feedback.show
                            ? feedback.severity
                            : mode === "edit" &&
                              [
                                  APIRequestStatus.success,
                                  APIRequestStatus.error,
                              ].includes(editStatus)
                            ? (editStatus as any)
                            : undefined
                    }
                >
                    {mode !== "edit" && feedback.show
                        ? feedback.message
                        : mode === "edit" &&
                          [
                              APIRequestStatus.success,
                              APIRequestStatus.error,
                          ].includes(editStatus)
                        ? editMessage
                        : ""}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CreateRole;
