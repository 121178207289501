import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    TextField,
} from "@mui/material";
import React, { FormEvent, useState, useContext, useEffect } from "react";
import { PublisherService } from "../../../services/PublisherService";
import { AppContext } from "../../AppContext";

type Props = {
    row?: any;
    updateMode?: boolean;
};

const EditPublisher = ({ row, updateMode }: Props) => {
    const [publisher, setPublisher] = useState<any>({
        name: "",
    });
    const { feedback, setFeedback } = useContext(AppContext);

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setPublisher({
            ...publisher,
            [ev.target.name]: value,
        });
    };

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        if (!updateMode) {
            const [data, err] = await PublisherService.createPublisher(
                publisher
            );
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        } else {
            const [data, err] = await PublisherService.editPublisher(
                row.id,
                publisher
            );
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        }
    };

    useEffect(() => {
        return () => {
            setFeedback({
                loading: false,
                message: "",
                severity: "success",
                show: false,
            });
        };
    }, []);

    useEffect(() => {
        if (updateMode) {
            setPublisher({
                name: row?.name,
            });
        }
    }, [updateMode]);
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    {feedback.show && (
                        <Alert severity={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label="Publisher Name"
                        value={publisher.name}
                        name="name"
                        size="small"
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {!updateMode
                            ? feedback.loading
                                ? "Adding publisher..."
                                : "Add publisher"
                            : feedback.loading
                            ? "updating publisher..."
                            : "update publisher"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default EditPublisher;
