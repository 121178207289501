import { Close, Edit } from "@mui/icons-material";
import {
  AppBar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Slide,
} from "@mui/material";
import React, { useState } from "react";
import AddBook from "./AddBook";

const EditBook = ({ row }: any) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Edit />
      </IconButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullScreen
        TransitionComponent={Slide}
      >
        <DialogTitle>
          <AppBar>
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>Edit Book Info</div>

              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>

        <DialogContent>
          <br /> <br /> <br />
          <AddBook editMode={true} rowToEdit={row} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditBook;
