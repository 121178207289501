import { ClearAll, Redo, Undo } from "@mui/icons-material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@mui/lab/";
import {
    Button,
    Grid,
    IconButton,
    TablePagination,
    Tooltip,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useGetAPI } from "../../services/Hooks";
import { Autocomplete } from "../AutoComplete";
import { baseAPI } from "../constants";

type Filter = {
    campus: number | string;
    student?: number | string;
    book?: number | string;

    [key: string]: any;
};

const BookHistory = () => {
    const [filter, setFilter] = useState<Filter>({
        campus: "",
        student: "",
        book: "",
        apply: 0,
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 10,
    });

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus: "",
            student: "",
            book: "",
            apply: 0,
        });
    };

    const handlePageChange = (ev: any, page: number) => {
        setPagination({ ...pagination, page: page });
    };

    const handleRowsPerPageChange = (ev: any) => {
        setPagination({ ...pagination, limit: ev.target.value });
    };

    const [history, count, status] = useGetAPI(
        `${baseAPI}/library/library-history`,
        pagination.page + 1,
        pagination.limit,
        {
            baseUser: filter.student ? filter.student : undefined,
            bookInstance: filter.book ? filter.book : undefined,
        },
        [filter.apply, pagination]
    );

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography variant="h4" color="primary" gutterBottom>
                    Books History
                </Typography>
            </Grid>

            <Grid item container spacing={1}>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/student/student-by-baseUser"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                student: c?.id || "",
                            })
                        }
                        label="Student"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                        processor={(opt) => ({
                            ...opt,
                            name: opt?.baseUser?.name,
                            id: opt?.baseUser?.id,
                        })}
                        apiParams={{ campus: filter.campus }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    {/* <SearchableInput
                        api={`${baseAPI}/library/book-instance`}
                        label="Book"
                        filter={filter}
                        setFilter={setFilter}
                        optionsPreprocessor={(opt) => ({
                            ...opt,
                            name: `BK-${opt?.book?.id}-${opt?.id}`,
                        })}
                        params={{ campus: filter.campus }}
                        dep={filter.campus}
                    /> */}

                    <Autocomplete
                        api="/library/book-instance"
                        setOutput={(c: any) =>
                            setFilter({
                                ...filter,
                                book: c?.id || "",
                            })
                        }
                        label="Book"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        clear={clearFilters}
                        processor={(opt) => ({
                            ...opt,
                            name: `BK-${opt?.book?.id}-${opt?.id}`,
                        })}
                        apiParams={{ campus: filter.campus }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        size="small"
                    >
                        {" "}
                        Apply Filter
                    </Button>
                </Grid>{" "}
                <Grid item xs={12} md={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAll />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Timeline position="alternate">
                    {history?.map((item: any, id: number) => (
                        <TimelineItem key={id}>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    {item.returnDate === null ? (
                                        <Redo />
                                    ) : (
                                        <Undo />
                                    )}
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>

                            <TimelineContent>
                                <Typography variant="h6">
                                    {item.bookInstance.book.name}
                                </Typography>

                                <Typography variant="body2">
                                    {item.returnDate === null
                                        ? "Issued To : " + item.baseUser.name
                                        : "Returned By : " + item.baseUser.name}
                                </Typography>

                                <Typography variant="body2">
                                    {item.returnDate === null
                                        ? "Issue Date : " +
                                          new Date(
                                              item.issueDate
                                          ).toDateString()
                                        : "Returned Date : " +
                                          new Date(
                                              item.returnDate
                                          ).toDateString()}
                                </Typography>

                                <Typography variant="body2">
                                    Duration :{" "}
                                    {new Date(item.duration).toDateString()}
                                </Typography>

                                <Typography variant="body2">
                                    Operator : {item.operator.name}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Grid>

            <Grid item>
                <TablePagination
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    count={count}
                    rowsPerPage={pagination.limit}
                    component="div"
                    page={pagination.page}
                />
            </Grid>
        </Grid>
    );
};

export default BookHistory;
