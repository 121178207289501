import {
    Close,
    Receipt as ReceiptIcon,
    ReceiptLong,
} from "@mui/icons-material";
import {
    Box,
    Checkbox,
    Dialog,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import {
    ChangeEvent,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Receipt } from ".";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import PrintTable from "../../Export/PrintTable";
import { deriveMonthsFromTimeRange } from "../ApplyFees";
import { FilterStudent } from "../FilterStudent";

export const receiptCopies = [
    { name: "Bank Copy" },
    { name: "Student Copy" },
    { name: "Accountant Copy" },
];

export const MultipleStudentsReceipt = () => {
    const { theme } = useContext(AppContext);
    const [printMode, setPrintMode] = useState(false);
    const printRef = useRef<any | null>(null);
    const [showReceipt, setShowReceipt] = useState<
        "single" | "multiple" | "none"
    >("none");
    const [receiptContent, setReceiptContent] = useState<JSX.Element | null>(
        null
    );
    const [students, setStudents] = useState<any[]>([]);
    const [filter, setFilter] = useState({
        campus: "",
        session: "",
        classroom: "",
        section: "",
        search: "",
        apply: 0,
        clear: false,
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 10,
        count: 0,
    });
    const [receiptInfo, setReceiptInfo] = useState({
        months: [],
        recordPerMonth: false,
        copies: [],
    });
    const monthsInSession = useMemo(() => {
        if (filter.session && students.length > 0) {
            return deriveMonthsFromTimeRange(
                new Date(students[0]?.session?.start),
                new Date(students[0]?.session?.end)
            );
        } else {
            return [];
        }
    }, [filter.session, students]);

    const handlePageChange = (ev: any, page: number) => {
        setPagination({ ...pagination, page });
    };

    const handleRowsPerPageChange = (ev: ChangeEvent<any>) => {
        setPagination({ ...pagination, limit: ev.target.value });
    };

    const getStudents = async () => {
        const finalFilter = Object.fromEntries(
            Object.entries(filter)
                .filter((item) => item[1] !== "")
                .filter(
                    (item: any) =>
                        !["campus", "search", "apply", "clear"].includes(
                            item[0]
                        )
                )
        );
        const [data, err] = await StudentService.getStudentRecord(
            pagination.page + 1,
            pagination.limit,
            { ...finalFilter, search: filter.search }
        );

        if (data) {
            setStudents(data.rows);
            setPagination({ ...pagination, count: data.count });
        }
    };

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setReceiptInfo({ ...receiptInfo, [ev.target.name]: ev.target.value });
    };

    const handleShowSingleReceipt = (studentId: string | number) => {
        const student = students.find(
            (student) => student.student.id == studentId
        );
        setReceiptContent(
            <Receipt
                student={{
                    ...student.student,
                    fullName: student.student.baseUser.name,
                    fullClassroom: `${student.student.classroom.name} (${student.student.section.name}), ${student.student.session.name}`,
                }}
                {...receiptInfo}
                session={student.session}
                classroom={student.classroom}
                enablePrint
            />
        );
        setShowReceipt("single");
    };

    const handleShowMultipleReceipts = (students: any[]) => {
        setReceiptContent(
            <Paper ref={printRef}>
                {students.map((classroomStudent) => (
                    <Paper elevation={0} square>
                        <Receipt
                            student={{
                                ...classroomStudent.student,
                                fullName:
                                    classroomStudent.student.baseUser.name,
                                fullClassroom: `${classroomStudent.student.classroom.name} (${classroomStudent.student.section.name}), ${classroomStudent.student.session.name}`,
                            }}
                            {...receiptInfo}
                            session={classroomStudent.session}
                            classroom={classroomStudent.classroom}
                        />
                    </Paper>
                ))}
            </Paper>
        );
        setShowReceipt("multiple");
    };

    useEffect(() => {
        if (filter.session) {
            setReceiptInfo((info: any) => ({ ...info, months: [] }));
        }
    }, [filter.session]);

    const handleCloseReceipt = () => {
        setShowReceipt("none");
        setReceiptContent(null);
    };

    useEffect(() => {
        console.log(filter.search);

        getStudents();
    }, [filter.apply, filter.search, pagination.page, pagination.limit]);

    useEffect(() => {
        if (filter.clear) {
            setStudents([]);
            setReceiptInfo({
                months: [],
                recordPerMonth: false,
                copies: [],
            });
        }
    }, [filter.clear]);

    return (
        <>
            <Dialog
                open={["single", "multiple"].includes(showReceipt)}
                fullWidth
                fullScreen
                onClose={handleCloseReceipt}
                PaperProps={{ sx: { bgcolor: "background.default" } }}
            >
                <Box display="flex" flexDirection="column">
                    <Box>
                        <Box
                            sx={{ bgcolor: "background.default" }}
                            width="100%"
                            height="5rem"
                            py={2}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <PrintTable
                                componentRef={printRef}
                                setPrintMode={setPrintMode}
                                sx={{
                                    visibility:
                                        showReceipt === "multiple"
                                            ? "visible"
                                            : "hidden",
                                }}
                            />

                            <IconButton
                                sx={{ mr: 2 }}
                                onClick={handleCloseReceipt}
                            >
                                <Close />
                            </IconButton>
                        </Box>
                        <Divider />
                    </Box>
                    <Box>
                        {/* <ThemeProvider theme={LightTheme}> */}
                        {receiptContent}
                        {/* </ThemeProvider> */}
                    </Box>
                </Box>
            </Dialog>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <FilterStudent filter={filter} setFilter={setFilter} />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        label="Months in receipt"
                        select
                        SelectProps={{ multiple: true }}
                        name="months"
                        value={receiptInfo.months}
                        onChange={handleChange}
                        helperText="Select a session to get its months"

                        // sx={{ overflowX: "auto" }}
                    >
                        {monthsInSession.map((month: any) => (
                            <MenuItem
                                key={month.monthNumber}
                                value={month}
                            >{`${month.month}, ${month.year}`}</MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        label="Copies to generate"
                        fullWidth
                        name="copies"
                        select
                        SelectProps={{ multiple: true }}
                        value={receiptInfo.copies}
                        onChange={handleChange}
                        helperText="Please select a copy"
                    >
                        {receiptCopies.map((copy: any) => (
                            <MenuItem key={copy.name} value={copy as any}>
                                {copy.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        label="Records per month"
                        control={
                            <Checkbox
                                value={receiptInfo.recordPerMonth}
                                onChange={(ev, value) =>
                                    setReceiptInfo({
                                        ...receiptInfo,
                                        recordPerMonth: value,
                                    })
                                }
                            />
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow
                                    sx={{ "&>*": { bgcolor: "action.hover" } }}
                                >
                                    <TableCell>File no.</TableCell>
                                    <TableCell>Enroll no.</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Father name</TableCell>
                                    <TableCell>Classroom</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => {
                                                handleShowMultipleReceipts(
                                                    students
                                                );
                                            }}
                                        >
                                            <ReceiptLong />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {students.map((classroomStudent) => (
                                    <TableRow
                                        key={classroomStudent?.student?.id}
                                    >
                                        <TableCell>
                                            {classroomStudent?.student?.fileNo}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                classroomStudent?.student
                                                    ?.enrollmentNo
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                classroomStudent?.student
                                                    ?.baseUser?.name
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                classroomStudent?.student
                                                    ?.fatherName
                                            }
                                        </TableCell>
                                        <TableCell>{`${classroomStudent?.student?.classroom?.name} (${classroomStudent?.student?.section?.name}), ${classroomStudent?.student?.session?.name}`}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() =>
                                                    handleShowSingleReceipt(
                                                        classroomStudent
                                                            ?.student?.id
                                                    )
                                                }
                                            >
                                                <ReceiptIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                            <TableFooter>
                                <TablePagination
                                    count={pagination.count}
                                    page={pagination.page}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={pagination.limit}
                                    onRowsPerPageChange={
                                        handleRowsPerPageChange
                                    }
                                />
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
};
