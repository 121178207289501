import { Box, Chip, makeStyles } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { CertificateService } from "../../../services/CertificateService";
import { AppContext } from "../../AppContext";
import { RUDTable } from "../../Table/RUDTable";
import AssignCertificate from "../AssignCertificate";
import { ShowUploadProfilePicture } from "../ShowUploadProfilePicture";
import PreviewCertificate from "./PreviewCertificate";

const initialFilter = {
    apply: 0,
    search: "",
    subject: "",
    employee: "",
    student: "",
    baseUser: "",
    paymentStatus: "",
    certificateStatus: "",
};

const CertificateHistory = () => {
    const [filter, setFilter] = useState(initialFilter);

    const { user } = useContext(AppContext);
    //
    const chipClasses = useChipStyles();

    useEffect(() => {
        if (user && user.baseUser.type === "student") {
            setFilter((f) => ({
                ...f,
                baseUser: user.baseUser.id,
                apply: f.apply + 1,
            }));
        }
    }, [user?.id]);

    return (
        <Box p={2}>
            <MultiPermissionAuthorize ops={["CREATE CERTIFICATE_HISTORY"]}>
                <AssignCertificate
                    onSuccess={() => {
                        setFilter((f) => ({ ...f, apply: f.apply + 1 }));
                    }}
                />
            </MultiPermissionAuthorize>

            <RUDTable
                getter={(page, limit) =>
                    CertificateService.getCertificatesHistory(page, limit, {
                        search: filter.search || undefined,
                        baseUser: filter.baseUser || undefined,
                        paymentStatus: filter.paymentStatus || undefined,
                        certificateStatus:
                            filter.certificateStatus || undefined,
                        subject:
                            filter.subject &&
                            !filter.employee &&
                            !filter.student
                                ? filter.subject
                                : undefined,
                    })
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    issueDate: new Date(row.issueDate).toDateString(),
                    issuedTo:
                        row.baseUser.type === "faculty"
                            ? row.baseUser.name
                            : row.baseUser.name,
                    name: row.certificate.name,
                })}
                customCols={[
                    {
                        header: "Type",
                        content: (row) => (
                            <Chip
                                style={{ textTransform: "capitalize" }}
                                label={row.certificateStatus}
                                variant="outlined"
                                color={
                                    row.certificateStatus === "orignal"
                                        ? "primary"
                                        : "secondary"
                                }
                            />
                        ),
                    },
                    {
                        header: "Preview",
                        content: (row) => (
                            <PreviewCertificate data={row} key={row.id} />
                        ),
                    },

                    {
                        header: "Upload Image",
                        content: (row) => (
                            <ShowUploadProfilePicture row={row} />
                        ),
                    },
                ]}
                ops={{
                    read: "READ CERTIFICATE_HISTORY",
                }}
                readables={{
                    id: "ID",
                    name: "Cerificate Name",
                    issueDate: "Issuing Date",
                    operatorName: "Issued By",
                    issuedTo: "Issue To",
                    Type: "Type",
                    Preview: "Preview Certificate",
                }}
                updatingAgents={[filter.search, filter.apply]}
                // filters={
                //     user.baseUser.type != "student" ? (
                //         <Filteration
                //             filter={filter}
                //             setFilter={setFilter}
                //             handleClear={() => {
                //                 setFilter(initialFilter);
                //             }}
                //             fields={[
                //                 <Autocomplete
                //                     label="Courses"
                //                     api="/class/subject/all-subjects"
                //                     labelKey="subject"
                //                     setOutput={(opt) => {
                //                         setFilter({
                //                             ...filter,
                //                             subject: opt?.id ?? "",
                //                         });
                //                     }}
                //                     textFieldProps={{ variant: "outlined" }}
                //                     // defaultValue={{ id: filter.subject }}
                //                     clear={!filter.subject}
                //                 />,

                //                 <Autocomplete
                //                     api={
                //                         filter.subject
                //                             ? "/employee/all-employees-subject"
                //                             : "/employee/all-employees"
                //                     }
                //                     apiParams={
                //                         filter.subject
                //                             ? {
                //                                   private: 1,
                //                                   classroom: user.classroom.id,
                //                                   subject: filter.subject,
                //                               }
                //                             : undefined
                //                     }
                //                     labelKey="name"
                //                     label="Employee"
                //                     setOutput={(value) => {
                //                         setFilter({
                //                             ...filter,
                //                             employee: value?.baseUser?.id ?? "",
                //                             baseUser: value?.baseUser?.id ?? "",
                //                         });
                //                     }}
                //                     textFieldProps={{ variant: "outlined" }}
                //                     // defaultValue={{ id: filter.employee }}
                //                     clear={!filter.subject}
                //                 />,

                //                 <Autocomplete
                //                     clear={!filter.employee || !filter.subject}
                //                     api={
                //                         filter.subject && filter.employee
                //                             ? "/class/subject/private"
                //                             : "/student/all-students"
                //                     }
                //                     apiParams={
                //                         filter.subject && filter.employee
                //                             ? {
                //                                   subject: filter.subject,
                //                               }
                //                             : undefined
                //                     }
                //                     processor={
                //                         filter.subject && filter.employee
                //                             ? (opt) => ({
                //                                   ...opt,
                //                                   name: opt.student.name,
                //                               })
                //                             : undefined
                //                     }
                //                     setOutput={
                //                         (val) => {
                //                             setFilter((f) => ({
                //                                 ...f,
                //                                 student:
                //                                     filter.subject &&
                //                                     filter.employee
                //                                         ? val?.user?.id ?? ""
                //                                         : val?.baseUser?.id ??
                //                                           "",
                //                                 baseUser:
                //                                     filter.subject &&
                //                                     filter.employee
                //                                         ? val?.user?.id ?? ""
                //                                         : val?.baseUser?.id ??
                //                                           "",
                //                             }));
                //                         }
                //                         // setPayment({
                //                         //     ...payment,
                //                         //     target: val?.baseUser?.id ?? "",
                //                         // })
                //                     }
                //                     labelKey="name"
                //                     label="Student"
                //                     textFieldProps={{
                //                         variant: "outlined",
                //                         required: true,
                //                     }}
                //                     // defaultValue={filter.student}
                //                 />,

                //                 <Autocomplete
                //                     labelKey="label"
                //                     label="Payment Status"
                //                     setOutput={(opt) => {
                //                         setFilter((f) => ({
                //                             ...f,
                //                             paymentStatus: opt?.value ?? "",
                //                         }));
                //                     }}
                //                     textFieldProps={{ variant: "outlined" }}
                //                     defaultOptions={[
                //                         {
                //                             id: 1,
                //                             label: "Pending",
                //                             value: "pending",
                //                         },
                //                         {
                //                             id: 2,
                //                             label: "Completed",
                //                             value: "active",
                //                         },
                //                     ]}
                //                 />,

                //                 <Autocomplete
                //                     labelKey="label"
                //                     label="Certificate Type"
                //                     setOutput={(opt) => {
                //                         setFilter((f) => ({
                //                             ...f,
                //                             certificateStatus: opt?.value ?? "",
                //                         }));
                //                     }}
                //                     textFieldProps={{ variant: "outlined" }}
                //                     defaultOptions={[
                //                         {
                //                             id: 1,
                //                             label: "Original",
                //                             value: "orignal",
                //                         },
                //                         {
                //                             id: 2,
                //                             label: "Duplicate / Provisional",
                //                             value: "provisional",
                //                         },
                //                     ]}
                //                 />,
                //             ]}
                //         />
                //     ) : (
                //         <></>
                //     )
                // }
                storageKey="certificateHistory"
            />
        </Box>
    );
};

export default CertificateHistory;

export const useChipStyles = makeStyles((theme) => ({
    success: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
    },

    warning: {
        borderColor: theme.palette.warning.main,
        color: theme.palette.warning.main,
    },

    primary: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },

    error: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
}));
