import { Card, CardContent } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Card elevation={0}>
      <CardContent></CardContent>
    </Card>
  );
};

export default Footer;
