import { InputAdornment, Typography } from "@material-ui/core";
import { Edit } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Slide,
  TextField,
} from "@mui/material";
import { capitalize } from "cypress/types/lodash";
import React, { FormEvent, useEffect, useState } from "react";
import { StockService } from "../../../services/StockService";
import { baseAPI } from "../../constants";
import SearchableInput from "../../SearchableInput";

const EditInstance = ({ row, changeCount, setChangeCount }: any) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<any>({
    book: row.id,
    status: row.status,
    condition: row.condition,
  });
  const [feedback, setFeedback] = useState<any>({
    hidden: true,
    severity: "",
    message: "",
    loading: false,
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFeedback({ ...feedback, loading: true });

    const [data, err] = await StockService.editStock(row.id, {
      campus: filter.campus,
      id: filter.newId,
      status: filter.status,
      condition: filter.condition,
    });

    if (data) {
      setFeedback({
        hidden: false,
        message: data.message,
        severity: "success",
        loading: false,
      });
    } else {
      setFeedback({
        hidden: false,
        message: err,
        severity: "error",
        loading: false,
      });
    }
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Edit />
      </IconButton>

      <Dialog
        open={open}
        onClose={() => {
          setChangeCount(changeCount + 1);
          setOpen(false);
        }}
        TransitionComponent={Slide}
        fullWidth
      >
        <DialogTitle>{row.accessationNo}</DialogTitle>

        <DialogContent>
          <Grid
            container
            py={1}
            spacing={1}
            component="form"
            onSubmit={handleSubmit}
          >
            {!feedback.hidden && (
              <Grid item xs={12}>
                <Alert security={feedback.severity}>{feedback.message}</Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <SearchableInput
                api={`${baseAPI}/org/campus`}
                label="Campus"
                filter={filter}
                setFilter={setFilter}
                multiple={false}
                defaultValue={row.campusDetail}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>{`BK-${row.book.id}-`}</Typography>
                    </InputAdornment>
                  ),
                }}
                label="ID"
                type="number"
                name="id"
                size="small"
                inputProps={{ min: 0 }}
                fullWidth
                onChange={(e) =>
                  setFilter({ ...filter, newId: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                size="small"
                label="Status"
                value={filter.status}
                fullWidth
                sx={{ textTransform: "capitalize" }}
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
              >
                <MenuItem value="available">Available</MenuItem>
                <MenuItem value="issued">Issued</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                size="small"
                label="Condition"
                value={filter.condition}
                fullWidth
                sx={{ textTransform: "capitalize" }}
                onChange={(e) =>
                  setFilter({ ...filter, condition: e.target.value })
                }
              >
                <MenuItem value="ok">Ok</MenuItem>
                <MenuItem value="damaged">Damaged</MenuItem>
                <MenuItem value="lost">Lost</MenuItem>
              </TextField>
            </Grid>

            <Grid xs={12} item mt={1}>
              <Button
                variant="outlined"
                type="submit"
                disabled={feedback.loading}
                endIcon={
                  feedback.loading ? <CircularProgress size="1rem" /> : null
                }
              >
                {feedback.loading ? "editing stock..." : "edit stock"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditInstance;
