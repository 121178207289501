import { Add, Edit } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    useTheme,
} from "@mui/material";
import { ReactElement, useState } from "react";

type Props = {
    label: string;
    children: JSX.Element;
    updateMode?: boolean;
    disabled?: boolean;
    triggerRowsRefetch?: () => void;
    maxWidth?: DialogProps["maxWidth"];
    icon?: ReactElement<any, any>;
};

const AddView = ({
    label = "add view",
    children,
    triggerRowsRefetch,
    updateMode = false,
    disabled = false,
    maxWidth = "xs",
    icon,
}: Props) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    return (
        <>
            {!updateMode ? (
                icon ? (
                    <IconButton
                        onClick={() => setOpen(true)}
                        disabled={disabled}
                    >
                        {icon}
                    </IconButton>
                ) : (
                    <Button
                        onClick={() => setOpen(true)}
                        variant="outlined"
                        startIcon={<Add />}
                        disabled={disabled}
                    >
                        {label}
                    </Button>
                )
            ) : (
                <IconButton
                    onClick={() => setOpen(true)}
                    disabled={disabled}
                    size="small"
                >
                    <Edit fontSize="small" />
                </IconButton>
            )}

            <Dialog
                maxWidth={maxWidth}
                open={open}
                sx={{ backdropFilter: "blur(5px)" }}
                onClose={() => {
                    if (triggerRowsRefetch) triggerRowsRefetch();
                    setOpen(false);
                }}
            >
                <DialogTitle sx={{ textTransform: "capitalize" }}>
                    {label}
                </DialogTitle>

                <DialogContent
                    sx={{
                        "&::-webkit-scrollbar": {
                            width: "6px",
                            borderRadius: "1000px",
                        },

                        "&::-webkit-scrollbar-thumb": {
                            background: theme.palette.divider,
                            borderRadius: "2.5px",
                        },
                    }}
                >
                    {children}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddView;
