import { ArrowDownward, ArrowUpward, ClearAll } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import BaseAccordian from "../../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../../MultiPermissionAuthorize";
import { StudentCategoryService } from "../../../../services/StudentCategoryService";
import AddView from "../../../AddView";
import { AppContext } from "../../../AppContext";
import { Search } from "../../../Filters/Search";
import { RUDTable } from "../../../Table/RUDTable";
import AddStudentCategory from "./AddStudentCategory";
import EditStudentCategory from "./EditStudentCategory";

export function StudentCategory() {
    const { user } = useContext(AppContext);
    const [filter, setfilter] = useState<any>({});
    const [changes, setChanges] = useState(0);
    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setfilter({ search: "" });
    };

    const ChangeStudentCategorySequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await StudentCategoryService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton onClick={() => handleLevelChange("up")}>
                    <Tooltip title="Move up">
                        <ArrowUpward />
                    </Tooltip>
                </IconButton>

                <IconButton onClick={() => handleLevelChange("down")}>
                    <Tooltip title="Move down">
                        <ArrowDownward />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Student Category
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE STUDENT_CATEGORY"]}>
                        <AddView
                            label="add student category"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddStudentCategory />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid item container spacing={2}>
                    <Grid item xs={10}>
                        {" "}
                        <Search
                            filter={filter}
                            setFilter={setfilter}
                            clear={clearFilters}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>
            <RUDTable
                getter={(page, limit) =>
                    StudentCategoryService.getStudentCategory(
                        page,
                        limit,
                        filter
                    )
                }
                editor={StudentCategoryService.editStudentCategory}
                deletor={
                    user?.baseUser?.ops.includes("DELETE STUDENT_CATEGORY")
                        ? (id: string | number) =>
                              StudentCategoryService.deleteStudentCategorySoft(
                                  id
                              )
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    //in here we will map nested values
                    deletionId: row.id,
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    Edit: "Edit",
                    Changesequence: "Change Sequence",
                }}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeStudentCategorySequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: user?.baseUser?.ops.includes(
                            "UPDATE STUDENT_CATEGORY"
                        )
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["UPDATE STUDENT_CATEGORY"]}
                            >
                                <AddView
                                    label="Edit "
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <EditStudentCategory row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter, changes]}
                ops={{
                    read: "READ STUDENT_CATEGORY",
                    edit: "",
                    delete: "DELETE STUDENT_CATEGORY",
                }}
                storageKey="studentCategory"
            />
        </div>
    );
}
