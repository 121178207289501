import {
    AttachMoneyOutlined,
    DeleteOutlined,
    EditOutlined,
    List as ListIcon,
    OpenInNewOutlined,
    ReceiptOutlined,
} from "@mui/icons-material";
import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { FamilyService } from "../../services/FamilyService";
import { APIRequestStatus, useGetAPI } from "../../services/Hooks";
import { S3Service } from "../../services/S3Service";
import { StudentService } from "../../services/StudentService";
import { AppContext } from "../AppContext";
import { awsDirStruct } from "../constants";

const ShowFamilyMembers = ({ show, setShow, members }: any) => {
    const [srcs, setSrcs] = useState<any>({});

    const getFamilyMemberImages = async () => {
        const images: any = {};

        for (const member of members) {
            const studentImagesSrcs = [];
            const [studentImagesData, studentImagesErr] =
                await StudentService.getStudentImages(member.member.id);

            if (studentImagesData) {
                for (const image of studentImagesData.images) {
                    const key = `${awsDirStruct.profile.picture}/${image.id}`;
                    const [data, err] = await S3Service.getS3ByKey(key);

                    if (data) {
                        studentImagesSrcs.push(data.url);
                    }
                }

                if (studentImagesSrcs.length > 0) {
                    images[member.id] = studentImagesSrcs.slice(-1)[0];
                }
            }
        }

        setSrcs(images);
    };

    useEffect(() => {
        getFamilyMemberImages();
    }, []);

    return show ? (
        <Dialog open={show} fullWidth>
            <DialogTitle>Family members</DialogTitle>
            <DialogContent>
                <List>
                    {members.map((fm: any) => (
                        <ListItem key={fm.id}>
                            <ListItemAvatar>
                                <Avatar src={srcs[fm.member.id] || ""} />
                            </ListItemAvatar>
                            <ListItemText primary={fm.member.baseUser.name} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    onClick={() => {
                                        window
                                            .open(
                                                `/show-student/${fm.member.baseUser.username}`,
                                                "_blank"
                                            )
                                            ?.focus();
                                    }}
                                >
                                    <OpenInNewOutlined />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
};

export const ViewFamily = () => {
    const { user } = useContext(AppContext);
    const [filter, setFilter] = useState({ name: "" });
    const [pagination, setPagination] = useState({
        count: 0,
        page: 0,
        limit: 10,
    });
    const [searchTrigger, setSearchTrigger] = useState(0);

    const [activeFamily, setActiveFamily] = useState<number | null>(null);
    const [showMembers, setShowMembers] = useState(false);

    const [deleteStatus, setDeleteStatus] = useState<any>({});

    const handleSearch = (ev: FormEvent<any>) => {
        ev.preventDefault();

        setSearchTrigger((n) => n + 1);
    };

    const handleChange = (ev: ChangeEvent<any>) => {
        setFilter({ ...filter, [ev.target.name]: ev.target.value });
    };

    const [families, fc, familyStatus, familyMessage] = useGetAPI(
        FamilyService.url,
        pagination.page + 1,
        pagination.limit,
        { search: filter.name },
        [searchTrigger],
        []
    );

    const handleShowFamilyMembers = (familyId: number) => {
        setActiveFamily(familyId);
        setShowMembers(true);
    };

    const handleDeleteFamily = async (familyId: number) => {
        setDeleteStatus({
            ...deleteStatus,
            [familyId]: APIRequestStatus.loading,
        });
        const [data, err] = await FamilyService.deleteSoft(familyId);

        if (data) {
            setDeleteStatus({
                ...deleteStatus,
                [familyId]: APIRequestStatus.success,
            });
            setSearchTrigger((n) => n + 1);
        } else {
            setDeleteStatus({
                ...deleteStatus,
                [familyId]: APIRequestStatus.error,
            });
        }
    };

    return (
        <Grid
            container
            spacing={1}
            alignItems="center"
            component="form"
            onSubmit={handleSearch}
        >
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    name="name"
                    value={filter.name}
                    label="Family name"
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={12} md={2}>
                <Button size="large" fullWidth variant="outlined" type="submit">
                    Search
                </Button>
            </Grid>

            {familyStatus === APIRequestStatus.loading && (
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            )}

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Members</TableCell>
                                {/* <TableCell>Receipt</TableCell>
                                <TableCell>Collect Fees</TableCell> */}

                                {user?.baseUser?.ops.includes(
                                    "GENERATE RECEIPT"
                                ) ? (
                                    <TableCell>Receipt</TableCell>
                                ) : null}

                                {user?.baseUser?.ops.includes("COLLECT FEE") ? (
                                    <TableCell>Collect Fees</TableCell>
                                ) : null}

                                {user?.baseUser?.ops.includes(
                                    "UPDATE FAMILY"
                                ) ? (
                                    <TableCell>Edit</TableCell>
                                ) : null}

                                {user?.baseUser?.ops.includes(
                                    "DELETE FAMILY"
                                ) ? (
                                    <TableCell>Edit</TableCell>
                                ) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {families.map((family: any) => (
                                <TableRow key={family.id}>
                                    <TableCell>{family.id}</TableCell>
                                    <TableCell>{family.name}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() =>
                                                handleShowFamilyMembers(
                                                    family.id
                                                )
                                            }
                                        >
                                            <ListIcon />
                                        </IconButton>
                                    </TableCell>

                                    {user?.baseUser?.ops.includes(
                                        "GENERATE RECEIPT"
                                    ) ? (
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    window
                                                        .open(
                                                            `/family/receipt?family=${family.id}`,
                                                            "_blank"
                                                        )
                                                        ?.focus();
                                                }}
                                            >
                                                <ReceiptOutlined />
                                            </IconButton>
                                        </TableCell>
                                    ) : null}

                                    {user?.baseUser?.ops.includes(
                                        "COLLECT FEE"
                                    ) ? (
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    window
                                                        .open(
                                                            `/collect-fees?family=${family.id}`,
                                                            "_blank"
                                                        )
                                                        ?.focus();
                                                }}
                                            >
                                                <AttachMoneyOutlined />
                                            </IconButton>
                                        </TableCell>
                                    ) : null}

                                    {user?.baseUser?.ops.includes(
                                        "UPDATE FAMILY"
                                    ) ? (
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    window
                                                        .open(
                                                            `/edit-family?id=${family.id}`,
                                                            "_blank"
                                                        )
                                                        ?.focus();
                                                }}
                                            >
                                                <EditOutlined />
                                            </IconButton>
                                        </TableCell>
                                    ) : null}

                                    {user?.baseUser?.ops.includes(
                                        "DELETE FAMILY"
                                    ) ? (
                                        <TableCell>
                                            {deleteStatus[family.id] ===
                                            APIRequestStatus.loading ? (
                                                <Icon>
                                                    <CircularProgress size="1em" />
                                                </Icon>
                                            ) : (
                                                <IconButton
                                                    onClick={() =>
                                                        handleDeleteFamily(
                                                            family.id
                                                        )
                                                    }
                                                >
                                                    <DeleteOutlined />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    ) : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                {activeFamily && (
                    <ShowFamilyMembers
                        show={showMembers}
                        setShow={setShowMembers}
                        members={
                            families.find((f: any) => f.id === activeFamily)
                                .members
                        }
                    />
                )}
            </Grid>
        </Grid>
    );
};
