import { Typography } from "@material-ui/core";
import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { BookService } from "../../../services/BookService";
import { S3Service } from "../../../services/S3Service";
import { awsDirStruct } from "../../constants";

export const ShowBookImage = ({ row }: any) => {
  const [src, setSrc] = useState("");

  const getBookImages = async () => {
    const bookImagesSrcs = [];
    const [bookImages, bookImagesErr] = await BookService.getBook(1, 0, {
      id: row.id,
    });

    if (bookImages) {
      for (const book of bookImages.rows) {
        const key = `${awsDirStruct.library.bookPictures}/${book.id}`;
        const [data, err] = await S3Service.getS3ByKey(key);

        if (data) {
          bookImagesSrcs.push(data.url);
        }
      }

      if (bookImagesSrcs.length > 0) {
        setSrc(bookImagesSrcs.slice(-1)[0]);
      }
    }
  };

  useEffect(() => {
    getBookImages();
  }, []);

  return (
    <Avatar src={src} variant="square" sx={{ width: 50, height: 50 }}></Avatar>
  );
};
