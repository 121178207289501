import { BrokenImageRounded } from "@mui/icons-material";
import { Avatar, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { UserService } from "../../../services/UserService";

export const HeaderStructure = ({
    selected,
    leftImage,
    rightImage,
    campus,
    additionalRow,
}: any) => {
    const [client, setClient] = useState<any>(null);

    const getClient = async () => {
        const [data, err] = await UserService.getClient();
        if (data) {
            setClient(data);
        }
    };

    useEffect(() => {
        getClient();
    }, []);

    return campus && client ? (
        <>
            <Grid
                container
                px={2}
                py={1}
                minHeight="5cm"
                borderRadius={1}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    {leftImage && (
                        <img
                            src={leftImage}
                            style={{
                                width: "75px",
                                height: "75px",
                            }}
                            alt=""
                        />
                    )}
                </Grid>
                <Grid item textAlign="center">
                    {selected.campusName ? (
                        <Typography variant="h4">{campus.name}</Typography>
                    ) : null}

                    {selected.campusAddress ? (
                        <Typography variant="h6">{campus.address} </Typography>
                    ) : null}

                    {selected.clientPhone ? (
                        <Typography variant="h6">
                            {client.client.phone}
                        </Typography>
                    ) : null}

                    {selected.clientEmail ? (
                        <Typography variant="h6">
                            {client.client.email}
                        </Typography>
                    ) : null}

                    {additionalRow ? (
                        <Typography variant="h6">{additionalRow}</Typography>
                    ) : null}
                </Grid>
                <Grid item>
                    {rightImage && (
                        <Avatar
                            src={rightImage}
                            sx={{ width: "75px", height: "75px" }}
                        >
                            <BrokenImageRounded
                                sx={{ width: "50%", height: "50%" }}
                            />
                        </Avatar>
                    )}
                </Grid>
            </Grid>
        </>
    ) : null;
};
