import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { S3Service } from "../../../services/S3Service";
import { StudentService } from "../../../services/StudentService";
import PrintTable from "../../Export/PrintTable";
import { awsDirStruct, dateFormatter } from "../../constants";

const MyTableCell = ({ children }: any) => {
    const theme = useTheme();
    return (
        <TableCell
            component="div"
            sx={{
                display: "flex",
                alignItems: "center",
                p: 0,
                height: "100%",
                justifyContent: "space-between",
            }}
        >
            <Typography variant="body1" sx={{ p: 2 }}>
                {children}
            </Typography>
            <Divider orientation="vertical" flexItem />
        </TableCell>
    );
};

export const ShowStudent = () => {
    const { username } = useParams<any>();
    const [printMode, setPrintMode] = useState(false);
    const [student, setStudent] = useState<any | null>(null);
    const [studentProfileSrc, setStudentProfileSrc] = useState("");
    const studentFormRef = useRef<ReactElement | null>(null);
    const theme = useTheme();

    const getStudentProfilePic = async () => {
        const key = `${awsDirStruct.profile.picture}/${username}`;
        const [data, err] = await S3Service.getS3ByKey(key);

        if (data) {
            setStudentProfileSrc(data.url);
        }
    };

    const getStudent = async () => {
        const [data, err] = await StudentService.getStudentByUsername(username);
        if (data) {
            setStudent(data.student);
        }
    };

    console.log(student);
    useEffect(() => {
        getStudent();
        getStudentProfilePic();
    }, [username]);

    return username ? (
        student ? (
            <div>
                {/* <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <StudentInfoCard
                            title="Personal Info"
                            data={{
                                Name: student.baseUser.name,
                                "Father Name": student.fatherName,
                                Gender: student.gender,
                                "Date of Birth": dateFormatter.format(
                                    new Date(student.dob)
                                ),
                                CNIC: student.studentCNIC,
                                "File No.": student.fileNo,
                                "Enrollment No.": student.enrollmentNo,
                                "SLC No.": student.slcNo,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <StudentInfoCard
                            title="Contact Info"
                            data={{
                                "Father Contact": student.fatherMobile,
                                "Father WhatsApp": student.fatherWhatsapp,
                                "Present Address": student.currentAddress,
                                "Permanent Address": student.permanentAddress,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <StudentInfoCard
                            title="Admission Info"
                            data={{
                                "Addmission Date": dateFormatter.format(
                                    new Date(student.admissionDate)
                                ),
                                "Addmission Status": student.admissionStatus,
                                Section: student.section.name,
                                Session: student.session.name,
                                Campus: student.campus.name,
                            }}
                        />
                    </Grid>
                </Grid> */}

                <PrintTable
                    componentRef={studentFormRef}
                    setPrintMode={setPrintMode}
                    printMode={printMode}
                />

                <Box ref={studentFormRef}>
                    <Table size="small" component={Paper}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography variant="h5" color="primary">
                                        Personal Info
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {Object.entries({
                                Name: student.baseUser.name || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                "Father Name": student.fatherName || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                Gender: student.gender || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                "Date of Birth": dateFormatter.format(
                                    new Date(student.dob)
                                ) || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                CNIC: student.studentCNIC || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                "File No.": student.fileNo || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                "Enrollment No.": student.enrollmentNo || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                "SLC No.": student.slcNo || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                            }).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{value}</TableCell>
                                </TableRow>
                            ))}

                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography variant="h5" color="primary">
                                        Contact Info
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {Object.entries({
                                "Father Contact": student.fatherMobile || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                "Father WhatsApp": student.fatherWhatsapp || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                "Present Address": student.currentAddress || (
                                    <Typography color={theme.palette.divider}>
                                        N/A
                                    </Typography>
                                ),
                                "Permanent Address":
                                    student.permanentAddress || (
                                        <Typography
                                            color={theme.palette.divider}
                                        >
                                            N/A
                                        </Typography>
                                    ),
                            }).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{value}</TableCell>
                                </TableRow>
                            ))}

                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography variant="h5" color="primary">
                                        Admission Info
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {Object.entries({
                                "Addmission Date": dateFormatter.format(
                                    new Date(student.admissionDate)
                                ),
                                "Addmission Status": student.admissionStatus,
                                Section: student.section.name,
                                Session: student.session.name,
                                Campus: student.campus.name,
                            }).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </div>
        ) : null
    ) : (
        <Typography variant="h3">Provide a user to show</Typography>
    );
};

// <Grid item container xs={12} justifyContent="space-between" alignItems="center">
//   School Logo
//   <Grid item>
//     <img
//       src={`${basePath}/images/icon.png`}
//       style={{
//         width: "150px",
//         height: "150px",
//       }}
//     />
//   </Grid>

// Header Text
//   <Grid item display="flex" flexDirection="column" alignItems="center">
//     <Typography variant="h4">{student.campus.name}</Typography>
//      <Typography variant="h6">
//                                     {student.currentAddress}
//                                 </Typography>
//                                 <Typography variant="h6">
//                                     {student.fatherMobile}
//                                 </Typography>
//   </Grid>

//   <Grid item>
//     <Avatar
//       src={studentProfileSrc}
//       style={{
//         width: "150px",
//         height: "150px",
//         fontSize: "5em",
//       }}
//     >
//       {student.baseUser.name.toUpperCase()[0]}
//     </Avatar>
//   </Grid>
// </Grid>;

type StudentInfoCardProps = { title: string; data: { [key: string]: string } };

const StudentInfoCard = ({ title, data }: StudentInfoCardProps) => {
    return (
        <Card variant="outlined" sx={{ height: "100%" }}>
            <CardHeader
                title={title}
                titleTypographyProps={{ color: "primary" }}
            />

            <CardContent>
                <Table size="small" sx={{ textTransform: "capitalize" }}>
                    {Object.entries(data).map((row, idx) => (
                        <TableRow key={idx}>
                            <TableCell>{row[0]}</TableCell>
                            <TableCell>{row[1]}</TableCell>
                        </TableRow>
                    ))}
                </Table>
            </CardContent>
        </Card>
    );
};
