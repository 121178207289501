import { CameraAlt } from "@mui/icons-material";
import {
    Alert,
    Avatar,
    Badge,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    Modal,
    TextField,
    useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { CampusService } from "../../../services/CampusService";
import { S3Service } from "../../../services/S3Service";
import { UserService } from "../../../services/UserService";
import { Autocomplete } from "../../AutoComplete";
import { awsDirStruct } from "../../constants";
import { UserRegions } from "./UserRegions";

const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};

type Props = {
    updateMode?: boolean;
    row?: any;
};

export default function AddUser({ updateMode, row }: Props) {
    const [user, setUser] = useState({
        name: "",
        username: "",
        password: "",
        picture: "",
        email: "",
        role: "",
        regions: [],
        allRegionAccess: false,
    });
    const [picture, setPicture] = useState<File | null>(null);
    const [feedback, setFeedback] = useState(initialFeedback);
    const [pictureFeedback, setPictureFeedback] = useState(initialFeedback);
    const [getcampuses, setCampuses] = useState([]);
    const [showImage, setShowImage] = useState(false);
    const [src, setSrc] = useState("");
    const theme = useTheme();
    const [filter, setFilter] = useState({
        campus: [],
        classroom: [],
        section: [],
    });
    const [changes, setChanges] = useState({
        campus: 0,
        classroom: 0,
        section: 0,
    });

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setPicture(event.target.files[0]);
        }
    };

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setUser({
            ...user,
            [ev.target.name]: value,
        });
    };

    const getCampus = async () => {
        const [data, err] = await CampusService.getCampus();
        setCampuses(data.rows);
    };

    const handlePictureUpload = async (ev: FormEvent) => {
        ev.preventDefault();
        setPictureFeedback({ ...pictureFeedback, loading: true });

        if (picture) {
            const key =
                awsDirStruct.profile.picture + "/" + row?.baseUser?.username;

            const [data, err] = await S3Service.uploadToS3(key, picture);

            if (data) {
                setPictureFeedback({
                    loading: false,
                    message: "Picture uploaded successfully!",
                    severity: "success",
                    hidden: true,
                });
            } else {
                setPictureFeedback({
                    loading: false,
                    message: "Error while uploading the picture!",
                    severity: "error",
                    hidden: true,
                });
            }
        }
    };

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        if (picture) {
            handlePictureUpload(ev);
        }

        setFeedback({ ...initialFeedback, loading: true });

        if (!updateMode) {
            const [data, err] = await UserService.createUser({
                ...user,
                username: user.username.trim(),
            });
            if (data) {
                if (picture) {
                    const { username } = data;
                    const key = awsDirStruct.profile.picture + "/" + username;

                    await S3Service.uploadToS3(key, picture);
                }

                setFeedback({
                    loading: false,
                    message: data.message,
                    hidden: false,
                    severity: "success",
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    hidden: false,
                    severity: "error",
                });
            }
        } else {
            const [data, err] = await UserService.editUser(row.id, {
                ...user,
                picture: undefined,
                username: user.username.trim(),
            });
            if (data) {
                /*  if (picture) {
          const { username } = data;
          const key = awsDirStruct.profile.picture + "/" + username;

          await S3Service.uploadToS3(key, picture);
        } */

                setFeedback({
                    loading: false,
                    message: data.message,
                    hidden: false,
                    severity: "success",
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    hidden: false,
                    severity: "error",
                });
            }
        }
    };

    const isDisabled = (): boolean => {
        let flag = false;
        if (feedback.loading) {
            flag = true;
        } else if (user.allRegionAccess) {
            flag = true;
        } else {
            flag = user.regions.length <= 0 ? false : true;
        }
        return !flag;
    };

    const getUserImage = async () => {
        const key = `${awsDirStruct.profile.picture}/${row.baseUser.username}`;
        const [data, err] = await S3Service.getS3ByKey(key);

        if (data) {
            setSrc(data.url);
        }
    };

    useEffect(() => {
        getCampus();
        getUserImage();

        if (updateMode) {
            setUser({
                name: row.name,
                username: row.username,
                password: row.baseUser.password,
                picture: "",
                email: row.email,
                role: row.baseUser.role.id,
                regions: row.baseUser.userRegions.map((region: any) => ({
                    campus: region.campus?.id,
                    classroom: region.classroom?.id,
                    section: region.section?.id,
                })),
                allRegionAccess: row.baseUser?.allRegionAccess,
            });

            setChanges({ campus: 1, classroom: 1, section: 1 });
        }
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                <Grid item xs={12}>
                    {feedback.loading && <LinearProgress />}
                    {!feedback.hidden && (
                        <Alert severity={feedback.severity as any}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid>

                {/* {feedback.show && (
                    <Alert severity={feedback.severity} sx={{ mb: 3 }}>
                        {feedback.message}
                    </Alert>
                )} */}

                <Grid item xs={12} textAlign="center">
                    <Badge
                        overlap="circular"
                        badgeContent={
                            <IconButton
                                size="small"
                                component="label"
                                sx={{
                                    bgcolor: theme.palette.background.paper,
                                }}
                            >
                                <CameraAlt fontSize="small" />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    hidden
                                />
                            </IconButton>
                        }
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <Avatar
                            imgProps={{
                                style: {
                                    borderRadius: "50px",
                                    cursor: picture ? "pointer" : undefined,
                                },
                                onClick: picture
                                    ? () => setShowImage(true)
                                    : undefined,
                            }}
                            src={picture ? URL.createObjectURL(picture) : src}
                            variant="circular"
                            sx={{
                                height: "100px",
                                width: "100px",
                                objectFit: "cover",
                                mx: "auto",
                                p: 0.75,
                                border: "2px solid",
                            }}
                        />
                    </Badge>
                </Grid>

                <Grid item xs={12}>
                    {picture && (
                        <Modal
                            onClose={() => setShowImage(false)}
                            open={showImage}
                            sx={{
                                padding: 4,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={URL.createObjectURL(picture)}
                                style={{
                                    width: "40%",
                                    borderRadius: 2,
                                }}
                            />
                        </Modal>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        api="/rolesystem/role"
                        setOutput={(r) =>
                            setUser({
                                ...user,
                                role: r?.id || "",
                            })
                        }
                        label="Role"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        defaultValue={updateMode && row.baseUser.role}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Name"
                        name="name"
                        fullWidth
                        value={user.name}
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        size="small"
                        variant="outlined"
                        name="username"
                        label="Username"
                        fullWidth
                        value={user.username}
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Email"
                        name="email"
                        fullWidth
                        value={user.email}
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        size="small"
                        fullWidth
                        name="password"
                        variant="outlined"
                        label="Password"
                        required
                        type="password"
                        value={user.password}
                        onChange={(ev) =>
                            setUser({
                                ...user,
                                password: ev.target.value.trim(),
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={user.allRegionAccess}
                                onChange={() =>
                                    setUser({
                                        ...user,
                                        allRegionAccess: !user.allRegionAccess,
                                    })
                                }
                            />
                        }
                        label="This user has access to all domains?"
                    />
                </Grid>

                {!user.allRegionAccess && (
                    <Grid item container spacing={1.5} xs={12}>
                        <UserRegions
                            user={user}
                            setUser={setUser}
                            updateMode={Boolean(updateMode)}
                            changes={changes}
                            setChanges={setChanges}
                        />
                    </Grid>
                )}

                <Grid item xs={12} mt={1}>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {updateMode
                            ? feedback.loading
                                ? "editing user..."
                                : "edit user"
                            : feedback.loading
                            ? "adding user..."
                            : "add user"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
