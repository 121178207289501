import { DeleteForever } from "@mui/icons-material";
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { S3Service } from "../../../services/S3Service";
import { UserService } from "../../../services/UserService";

const DeleteUser = ({ row, changes, setChanges }: any) => {
    const [open, setOpen] = useState(false);
    const [feedback, setFeedback] = useState({
        show: false,
        message: "",
        loading: false,
        severity: "",
    });

    const handleDelete = async (id: string | number) => {
        setFeedback({ ...feedback, loading: true });
        const [data, err] = await UserService.deleteUserSoft(id);

        if (data) {
            const [s3res, s3err] = await S3Service.deleteS3ByKey(row?.s3Key);

            if (s3res) {
                console.log(s3res);
            }

            setFeedback({
                loading: false,
                show: true,
                message: data.message,
                severity: "success",
            });
        } else {
            setFeedback({
                loading: false,
                show: true,
                message: err,
                severity: "error",
            });
        }

        setChanges(changes + 1);
    };

    useEffect(() => {
        return () => {
            setFeedback({
                message: "",
                loading: false,
                severity: "warning",
                show: false,
            });
        };
    }, []);

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <DeleteForever />
            </IconButton>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Permanently Delete</DialogTitle>

                <DialogContent>
                    {feedback.show && (
                        <Alert severity={feedback.severity as any}>
                            {feedback.message}
                        </Alert>
                    )}
                    <Typography mt={2}>
                        This will irreversibly delete the item, continue?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)}>close</Button>

                    <Button
                        color="error"
                        onClick={() => handleDelete(row?.deletionId)}
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1rem" />
                            ) : undefined
                        }
                    >
                        {feedback.loading ? "deleting user..." : "delete"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteUser;
