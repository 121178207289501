import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    IconButton,
    Snackbar,
    Typography,
    useTheme,
} from "@mui/material";
// import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { usePatchAPI, usePostAPI } from "../../../services/Hooks";
import { S3Service } from "../../../services/S3Service";
import { awsDirStruct, baseAPI } from "../../constants";
import { Certificate, Footer } from "../Certificates";
import CertificateText from "./CertificateText";
import FooterControl from "./Footer";
import Header from "./Header";
import Variables from "./Variables";

type Props = {
    certificate: Certificate;
    setCertificate: Dispatch<SetStateAction<Certificate>>;
    mode: "create" | "update";
};

type Pagination = {
    campus: { page: number; limit: number };
};

// const useStyles = makeStyles((theme: Theme) => ({
//     scrollBar: {
//         "&::-webkit-scrollbar": {
//             width: "6px",
//             borderRadius: "1000px",
//         },

//         "&::-webkit-scrollbar-thumb": {
//             background: theme.palette.background.default,
//             borderRadius: "2.5px",
//         },

//         "&::-webkit-scrollbar-track": {
//             background: "transparent",
//         },
//     },
// }));

type CertVariable = { [key: string]: { label: string; checked: boolean } };

type CertificateVariables = {
    student: CertVariable;
    employee: CertVariable;
};

const Menu = ({ certificate, setCertificate, mode }: Props) => {
    const [pagination, setPagination] = useState<Pagination>({
        campus: { page: 1, limit: 0 },
    });
    const [feedback, setFeedback] = useState<any>({
        message: "",
        loading: false,
        hidden: true,
        severity: "",
    });
    const [filter, setFilter] = useState<any>({
        type: "student",
    });

    const [variables, setVariables] = useState<CertificateVariables>({
        student: {
            name: { label: "Name", checked: true },
            fatherName: { label: "Father Name", checked: true },
            campus: { label: "Campus", checked: true },
            gender: { label: "Gender", checked: false },
            fileNo: { label: "File No.", checked: false },
            slcNo: { label: "SLC No.", checked: false },
            dob: { label: "Date of Birth", checked: false },
        },

        employee: {
            name: { label: "Name", checked: true },
            dob: { label: "Date of Birth", checked: false },
            className: { label: "Class Name", checked: false },
        },
    });

    const theme = useTheme();

    const selectedVariables = useMemo(
        () =>
            Object.entries(variables)
                .map(([k, v]: any) => ({
                    [k]: Object.values(v)
                        .filter((item: any) => item.checked === true)
                        .map((item: any) => item.label),
                }))
                .filter((item) => item[filter.type] !== filter.item),
        [variables, filter.type]
    );

    const [saveCertificate, response, status, messege] = usePostAPI(
        `${baseAPI}/certificate`
    );

    const [updateCertificate, updationRes, updationStatus, updationMessege] =
        usePatchAPI(`${baseAPI}/certificate`);

    async function handleFooterImages(
        images: (Footer & { type: "image" })[],
        newCertificateId: number
    ) {
        console.log(images);

        const key = `${awsDirStruct.org.picture}/${newCertificateId}/footer/`;

        const promises = images.map((img) =>
            S3Service.uploadToS3(key + img.id, img.image)
        );

        Promise.allSettled(promises).then((res) => {
            console.log(res);
        });

        // const [data, err] = await S3Service.getS3ByKey(key);
    }

    function handleUpdateCertificate() {
        setFeedback({ ...feedback, loading: true });

        const { fields } = certificate;

        updateCertificate(
            {
                name: certificate.name,
                type: certificate.type,
                content: JSON.stringify({
                    title: fields.title,
                    header: fields.header,
                    subTitle: fields.subTitle,
                    userName: fields.userName,
                    body: fields.body,
                    footer: fields.footer
                        ? {
                              ...fields.footer,
                              fields: (fields.footer?.fields ?? []).map(
                                  (item) => ({
                                      ...item,
                                      image: "",
                                      url: "",
                                  })
                              ),
                          }
                        : undefined,
                    additionalBody: fields.additionalBody,
                }),
            },
            { id: certificate?.id }
        );
    }

    const handleSaveCertificate = async () => {
        setFeedback({ ...feedback, loading: true });

        const { fields } = certificate;

        saveCertificate(
            {
                name: certificate.name,
                type: certificate.type,
                content: JSON.stringify({
                    title: fields.title,
                    header: fields.header,
                    subTitle: fields.subTitle,
                    userName: fields.userName,
                    body: fields.body,
                    footer: fields.footer
                        ? {
                              ...fields.footer,
                              fields: fields.footer.fields?.map((item) => ({
                                  ...item,
                                  image: "",
                                  url: "",
                              })),
                          }
                        : undefined,
                    additionalBody: fields.additionalBody,
                }),
            },
            {}
        );
    };

    useEffect(() => {
        if (status === "success") {
            const { fields } = certificate;

            const footerImages = fields.footer?.fields
                ? fields.footer?.fields.filter((item) =>
                      mode === "create"
                          ? item.type === "image"
                          : item.type === "image" && item.image
                  )
                : [];

            if (footerImages.length > 0) {
                handleFooterImages(
                    footerImages as any,
                    response.certificate.id
                );
            }

            setFeedback({
                message: response.message,
                loading: false,
                severity: "success",
                hidden: false,
            });
        }
        if (status === "error") {
            setFeedback({
                message: messege,
                loading: false,
                severity: "error",
                hidden: false,
            });
        }
    }, [status]);

    useEffect(() => {
        if (updationStatus === "success") {
            const { fields } = certificate;

            const footerImages = fields.footer?.fields
                ? fields.footer?.fields.filter(
                      (item) => item.type === "image" && item.image
                  )
                : [];

            if (footerImages.length > 0) {
                handleFooterImages(footerImages as any, certificate.id!);
            }

            setFeedback({
                message: updationRes.message,
                loading: false,
                severity: "success",
                hidden: false,
            });
        }
        if (updationStatus === "error") {
            setFeedback({
                message: updationMessege,
                loading: false,
                severity: "error",
                hidden: false,
            });
        }
    }, [updationStatus]);

    return (
        <>
            {/* <Option label="Select a user type">
                <UserType filter={filter} setFilter={setFilter} />
            </Option> */}

            <Option label="Header">
                <Header
                    certificate={certificate}
                    setCertificate={setCertificate}
                />
            </Option>

            <Option label="Variables">
                <Variables
                    variables={variables}
                    setVariables={setVariables}
                    userType={filter?.type}
                />
            </Option>

            <Option label="Certificate Text">
                <CertificateText
                    certificate={certificate}
                    setCertificate={setCertificate}
                    variables={selectedVariables}
                    userType={filter.type}
                />
            </Option>

            <Option label="Footer">
                <FooterControl
                    certificate={certificate}
                    setCertificate={setCertificate}
                    mode={mode}
                />
            </Option>

            <Box
                bgcolor={theme.palette.background.paper}
                position="sticky"
                bottom={0}
            >
                <Button
                    color="primary"
                    fullWidth
                    variant="outlined"
                    onClick={
                        mode === "create"
                            ? handleSaveCertificate
                            : handleUpdateCertificate
                    }
                    disabled={feedback.loading}
                    endIcon={
                        feedback.loading ? (
                            <CircularProgress size="1rem" />
                        ) : null
                    }
                >
                    {mode === "create"
                        ? feedback.loading
                            ? "creating template..."
                            : "create template"
                        : feedback.loading
                        ? "updating template..."
                        : "update template"}
                </Button>
            </Box>

            <Snackbar
                open={!feedback.hidden}
                onClose={() =>
                    setFeedback((f: any) => ({ ...f, hidden: true }))
                }
                autoHideDuration={5000}
            >
                <Alert severity={feedback.severity} style={{ margin: 2 }}>
                    {feedback.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Menu;

// ====================================

type OptionProps = {
    label: string;
    children: JSX.Element;
};

const Option = ({ label, children }: OptionProps) => {
    const [open, setOpen] = useState(false);

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={2}
                py={1}
                style={{ cursor: "pointer" }}
                onClick={() => setOpen(!open)}
            >
                <Typography>{label}</Typography>

                <IconButton size="small" onClick={() => setOpen(!open)}>
                    {open ? (
                        <ExpandLess fontSize="small" />
                    ) : (
                        <ExpandMore fontSize="small" />
                    )}
                </IconButton>
            </Box>

            <Divider />

            <Collapse in={open}>
                <Box p={2}>{children}</Box>
            </Collapse>

            <Divider />
        </Box>
        // <Accordion variant="outlined">
        //     <AccordionSummary expandIcon={<ExpandMore />}>
        //         {label}
        //     </AccordionSummary>

        //     <AccordionDetails>{children}</AccordionDetails>
        // </Accordion>
    );
};
