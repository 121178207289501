import { Book, Height, MenuBook } from "@mui/icons-material";
import {
  Avatar,
  Dialog,
  DialogContent,
  IconButton,
  Modal,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BookService } from "../../../services/BookService";
import { S3Service } from "../../../services/S3Service";
import { awsDirStruct } from "../../constants";

export const ShowBookPdf = ({ row }: any) => {
  const [src, setSrc] = useState("");
  const [open, setOpen] = useState(false);

  const getBookPdf = async () => {
    const bookPdfSrcs = [];
    const [bookPdfs, bookPdfsErr] = await BookService.getBook(1, 0, {
      id: row.id,
    });

    if (bookPdfs) {
      for (const book of bookPdfs.rows) {
        const key = `${awsDirStruct.library.bookPdfs}/${book.id}`;
        const [data, err] = await S3Service.getS3ByKey(key);

        if (data) {
          bookPdfSrcs.push(data.url);
        }
      }

      if (bookPdfSrcs.length > 0) {
        setSrc(bookPdfSrcs.slice(-1)[0]);
      }
    }
  };

  useEffect(() => {
    getBookPdf();
  }, []);

  return (
    <>
      {src ? (
        <IconButton onClick={() => setOpen(true)}>
          <MenuBook />
        </IconButton>
      ) : (
        <MenuBook />
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ height: "90vh", width: "90%", margin: "auto" }}
      >
        <iframe
          src={src}
          style={{ width: "100%", height: "100%", border: 0 }}
        ></iframe>
      </Modal>
    </>
  );
};
