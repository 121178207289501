import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React, { ChangeEvent, useEffect, useState } from "react";
import { POST } from "../../../services/BaseService";
import { BaseBreadcrum } from "../../BaseBreadcrum";

type Props = {
  dialogView?: boolean;
};

const AddGenre = ({ dialogView }: Props) => {
  const [genre, setGenre] = useState({ name: "", description: "" });
  const [feedback, setFeedback] = useState({
    message: "",
    loading: false,
    hidden: true,
    severity: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setGenre({ ...genre, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setFeedback({ ...feedback, loading: true });
    const [res, err] = await POST("/library/genres", genre);

    if (res) {
      setFeedback({
        ...feedback,
        message: res.message,
        severity: "success",
        hidden: false,
        loading: false,
      });
    }
    if (err) {
      setFeedback({
        ...feedback,
        message: err,
        severity: "error",
        hidden: false,
        loading: false,
      });
    }
  };

  useEffect(() => {
    return () =>
      setFeedback({
        ...feedback,
        message: "",
        severity: "success",
        hidden: true,
        loading: false,
      });
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          {feedback.loading && <LinearProgress />}
          {!feedback.hidden && (
            <Alert severity={feedback.severity as any}>
              {feedback.message}
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            size="small"
            variant="outlined"
            label="Name"
            name="name"
            fullWidth
            value={genre.name}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            size="small"
            rows={3}
            multiline
            variant="outlined"
            label="Description"
            name="description"
            fullWidth
            value={genre.description}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            disabled={feedback.loading}
            endIcon={feedback.loading ? <CircularProgress size="1em" /> : null}
          >
            {feedback.loading ? "Adding Genre..." : "Add Genre"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddGenre;
