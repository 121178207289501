import { makeStyles } from "@material-ui/core";
import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Theme,
} from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";
import { SubjectService } from "../../../services/SubjectService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";

const useStyles = makeStyles((theme: Theme) => ({
    breadcrum: {
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
    },
}));

type Props = {
    row?: any;
    updateMode?: boolean;
};

const EditSubject = ({ row, updateMode = false }: Props) => {
    const [subject, setSubject] = useState({
        name: "",
        classroom: "",
        publisher: "",
        campus: "",
    });

    const { feedback, setFeedback } = useContext(AppContext);

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setSubject({
            ...subject,
            [ev.target.name]: value,
        });
    };

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        if (!updateMode) {
            const [data, err] = await SubjectService.createSubject({
                ...subject,
                publisher:
                    subject.publisher === "No publisher"
                        ? ""
                        : subject.publisher,
            });
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        } else {
            const [data, err] = await SubjectService.editSubject(row.id, {
                ...subject,
                publisher:
                    subject.publisher === "No publisher"
                        ? ""
                        : subject.publisher,
                campus: undefined,
            });
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        }
    };

    useEffect(() => {
        return () =>
            setFeedback({
                loading: false,
                message: "",
                severity: "success",
                show: false,
            });
    }, []);

    useEffect(() => {
        if (updateMode) {
            setSubject({
                name: row.name,
                classroom: row?.classroom?.id,
                publisher: row?.publisher?.id,
                campus: row.classroom.campus.id,
            });
        }
    }, [updateMode]);
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    {feedback.show && (
                        <Alert severity={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid>

                <Grid item xs={12} md={10}>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Subject"
                        name="name"
                        required
                        value={subject.name}
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={10}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setSubject({
                                ...subject,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        defaultValue={updateMode && row.classroom.campus}
                    />
                </Grid>

                <Grid item xs={12} md={10}>
                    <Autocomplete
                        api="/org/academics/classroom"
                        setOutput={(c) =>
                            setSubject({
                                ...subject,
                                classroom: c?.id || "",
                            })
                        }
                        label="Classroom"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            campus: subject.campus
                                ? subject.campus
                                : row.classroom.campus.id,
                        }}
                        defaultValue={updateMode && row.classroom}
                    />
                </Grid>

                <Grid item xs={12} md={10}>
                    <Autocomplete
                        api="/org/academics/publisher"
                        setOutput={(p) =>
                            setSubject({
                                ...subject,
                                publisher: p?.id || "",
                            })
                        }
                        label="Publisher"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        defaultValue={updateMode && row.publisher}
                    />
                </Grid>

                <Grid item xs={12} mt={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {!updateMode
                            ? feedback.loading
                                ? "Adding subject..."
                                : "Add subject"
                            : feedback.loading
                            ? "updating subject..."
                            : "update subject"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default EditSubject;
