import { Add, EditOutlined, RemoveCircle, Search } from "@mui/icons-material";
import {
    Alert,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FamilyService } from "../../services/FamilyService";
import { APIRequestStatus } from "../../services/Hooks";
import { StudentService } from "../../services/StudentService";

export const CreateFamily = ({ editMode = false }: { editMode?: boolean }) => {
    const location = useLocation();
    const [family, setFamily] = useState<any>({ name: "", members: [] });
    const [students, setStudents] = useState<any[]>([]);
    const [studentFilter, setStudentFilter] = useState({
        fileNo: "",
        enrollmentNo: "",
    });
    const [studentSearchStatus, setStudentSearchStatus] = useState(
        APIRequestStatus.idle
    );
    const [familyStatus, setFamilyStatus] = useState(APIRequestStatus.idle);
    const [familyMessage, setFamilyMessage] = useState("");
    const [preEditFamily, setPreEditFamily] = useState<any>(null);

    const handleChange = (ev: ChangeEvent<any>) => {
        setFamily((f: any) => ({ ...f, [ev.target.name]: ev.target.value }));
    };

    const handleStudentFilterChange = (ev: ChangeEvent<any>) => {
        setStudentFilter((f: any) => ({
            ...f,
            [ev.target.name]: ev.target.value,
        }));
    };

    const handleSearchStudent = async () => {
        setStudentSearchStatus(APIRequestStatus.loading);

        const [data, err] = await StudentService.getStudent(1, 1, {
            search: studentFilter.fileNo
                ? studentFilter.fileNo
                : studentFilter.enrollmentNo
                ? studentFilter.enrollmentNo
                : undefined,
        });

        if (data && data.count > 0) {
            const _newFamily: any = {
                ...family,
                members: Array.from(
                    new Set([...family.members, data.rows[0].id])
                ),
            };
            setFamily(_newFamily);

            const _newStudents = [...students, data.rows[0]];
            const _newStudentUniqueIds = Array.from(
                new Set(_newStudents.map((s: any) => s.id))
            );
            const uniqueStudents: any[] = [];

            for (const uniqueId of _newStudentUniqueIds) {
                const targetStudent = _newStudents.find(
                    (s: any) => s.id == uniqueId
                );
                if (targetStudent) {
                    uniqueStudents.push(targetStudent);
                }
            }

            setStudents(uniqueStudents);
            setStudentSearchStatus(APIRequestStatus.success);
        } else {
            setStudentSearchStatus(APIRequestStatus.error);
        }

        setStudentFilter({ fileNo: "", enrollmentNo: "" });
    };

    const handleRemoveStudent = (id: number) => {
        setStudents((ss: any[]) => ss.filter((s) => s.id != id));
        setFamily((f: any) => ({
            ...f,
            members: f.members.filter((m: any) => m != id),
        }));
    };

    const handleCreateFamily = async (ev: FormEvent<any>) => {
        ev.preventDefault();
        setFamilyStatus(APIRequestStatus.loading);
        setFamilyMessage("");

        const [data, err] = await FamilyService.createFromMembers(family);

        if (data) {
            setFamilyStatus(APIRequestStatus.success);
            setFamilyMessage(data.message);
        } else {
            setFamilyStatus(APIRequestStatus.error);
            setFamilyMessage(err);
        }
    };

    const handleUpdateFamily = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setFamilyStatus(APIRequestStatus.loading);
        setFamilyMessage("");

        const [data, err] = await FamilyService.editFamily(
            preEditFamily.id,
            family
        );

        if (data) {
            setFamilyStatus(APIRequestStatus.success);
            setFamilyMessage(data.message);
        } else {
            setFamilyStatus(APIRequestStatus.error);
            setFamilyMessage(err);
        }
    };

    const getFamily = async () => {
        const params = new URLSearchParams(location.search);
        const _fid = params.get("id");

        if (_fid) {
            const [family, err] = await FamilyService.getFamilyByID(_fid);

            if (family) {
                setPreEditFamily(family);
                setFamily({
                    name: family.name,
                    members: family.members.map((fm: any) => fm.member.id),
                });
                setStudents(family.members.map((fm: any) => fm.member));
            }
        }
    };

    useEffect(() => {
        if (editMode) {
            getFamily();
        }
    }, [location]);

    return (
        <Grid
            container
            spacing={1}
            component="form"
            onSubmit={editMode ? handleUpdateFamily : handleCreateFamily}
        >
            {["success", "error"].includes(familyStatus) && (
                <Grid item xs={12} mb={1}>
                    <Alert severity={familyStatus as any}>
                        {familyMessage}
                    </Alert>
                </Grid>
            )}

            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    name="name"
                    label="Family name"
                    value={family.name}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider textAlign="left">
                    <Typography variant="body2" color="action.disabled">
                        Search students to add
                    </Typography>
                </Divider>
            </Grid>

            <Grid item container spacing={1} xs={12} alignItems="center">
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        name="fileNo"
                        label="File no."
                        value={studentFilter.fileNo}
                        onChange={handleStudentFilterChange}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        name="enrollmentno"
                        label="Enrollment no."
                        value={studentFilter.enrollmentNo}
                        onChange={handleStudentFilterChange}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        onClick={handleSearchStudent}
                        endIcon={
                            studentSearchStatus === APIRequestStatus.loading ? (
                                <CircularProgress size="1em" />
                            ) : (
                                <Search />
                            )
                        }
                    >
                        Search
                    </Button>
                </Grid>

                {["success", "error"].includes(studentSearchStatus) && (
                    <Grid item xs={12}>
                        <Alert severity={studentSearchStatus as any}>
                            {studentSearchStatus === APIRequestStatus.success
                                ? "Student found successfully"
                                : "Student not found"}
                        </Alert>
                    </Grid>
                )}
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Father name</TableCell>
                                <TableCell>File no.</TableCell>
                                <TableCell>Enrollment no.</TableCell>
                                <TableCell>Classroom</TableCell>
                                <TableCell>Remove</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {students.map((s: any) => (
                                <TableRow key={s.id}>
                                    <TableCell>{s.baseUser.name}</TableCell>
                                    <TableCell>{s.fatherName}</TableCell>
                                    <TableCell>{s.fileNo}</TableCell>
                                    <TableCell>{s.enrollmentNo}</TableCell>
                                    <TableCell>{`${s.classroom.name} (${s.section.name}), ${s.session.name}`}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() =>
                                                handleRemoveStudent(s.id)
                                            }
                                        >
                                            <RemoveCircle color="error" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end">
                <Grid item xs={12} md={3}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        endIcon={
                            familyStatus === APIRequestStatus.loading ? (
                                <CircularProgress size="1em" />
                            ) : editMode ? (
                                <EditOutlined />
                            ) : (
                                <Add />
                            )
                        }
                    >
                        {editMode ? "Edit Family" : "Create Family"}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
