import { Image } from "@mui/icons-material";
import { Grow, IconButton, Modal, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { S3Service } from "../../services/S3Service";
import { awsDirStruct } from "../constants";

type Props = {
    id: string;
    simpleView?: boolean;
    objectFit?: string;
};

const ImagePreview = ({ id, simpleView, objectFit = "contain" }: Props) => {
    const [open, setOpen] = useState(simpleView ? simpleView : false);
    const [fetchImage, setFectch] = useState(false);
    const [pic, setPic] = useState<any>();
    const theme = useTheme();

    const getImage = async () => {
        const key = `${awsDirStruct.profile.picture}/${id}`;
        const [data, err] = await S3Service.getS3ByKey(key);

        if (data) {
            setPic(data.url);
        }
    };

    useEffect(() => {
        if (fetchImage) {
            getImage();
        }
    }, [fetchImage]);

    return (
        <>
            {!simpleView && (
                <IconButton
                    onClick={() => {
                        setOpen(true);
                        setFectch(true);
                    }}
                    size="small"
                >
                    <Image fontSize="small" />
                </IconButton>
            )}

            {simpleView ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(4px)",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={pic}
                            alt=""
                            style={{
                                objectFit: "contain",
                                height: "90%",
                                margin: "auto 0",
                            }}

                            // height={420}
                            // width={theme.breakpoints.values.sm}
                        />
                    </div>
                </div>
            ) : (
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(4px)",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Grow in={open}>
                            {/* <Image
              src={pic}
              alt=""
              height={420}
              width={theme.breakpoints.values.sm}
              style={{
                aspectRatio: "16/9",
                objectFit: "contain",
              }}
            /> */}

                            <img
                                src={pic}
                                alt=""
                                style={{
                                    // aspectRatio: "3/5",
                                    objectFit: "contain",
                                    height: "90%",
                                    margin: "auto 0",
                                }}
                            />
                        </Grow>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ImagePreview;
