import { CircularProgress } from "@material-ui/core";
import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";
import { SectionServices } from "../../../services/SectionServices";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";

const AddSection = ({ row, updateMode = false }: any) => {
    const [filter, setFilter] = useState({
        campus: "",
        classroom: "",
        name: "",
    });

    const { feedback, setFeedback } = useContext(AppContext);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setFeedback({ ...feedback, loading: true });

        const customFieldsComponent = {
            classroom: filter.classroom,
            sections: [{ name: filter.name.trim() }],
        };

        const [data, err] = await SectionServices.createSection(
            customFieldsComponent
        );

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                show: true,
                severity: "success",
            });
        } else {
            setFeedback({
                loading: false,
                message: err,
                show: true,
                severity: "error",
            });
        }
    };

    useEffect(() => {
        return () => {
            setFeedback({
                message: "",
                loading: false,
                severity: "warning",
                show: false,
            });
        };
    }, []);

    return (
        <Box>
            <Grid
                container
                spacing={1.5}
                mt={1.5}
                component="form"
                onSubmit={handleSubmit}
            >
                {feedback.show && (
                    <Grid item xs={12} mb={1.5}>
                        <Alert severity={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    </Grid>
                )}

                <Grid item xs={12} md={12}>
                    {" "}
                    <TextField
                        label="Name"
                        fullWidth
                        size="small"
                        name="name"
                        onChange={(e) =>
                            setFilter({ ...filter, name: e.target.value })
                        }
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setFilter({
                                ...filter,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Autocomplete
                        api="/org/academics/classroom"
                        setOutput={(c) =>
                            setFilter({
                                ...filter,
                                classroom: c?.id || "",
                            })
                        }
                        label="Classroom"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{ campus: filter.campus }}
                    />
                </Grid>

                <Grid item md={12}>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1rem" />
                            ) : null
                        }
                    >
                        {feedback.loading ? "adding section..." : "add section"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddSection;
