import { CircularProgress } from "@material-ui/core";
import { Add, Remove } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import {
    ChangeEvent,
    FormEvent,
    FormEventHandler,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { StudentCategoryService } from "../../../../services/StudentCategoryService";
import { AppContext } from "../../../AppContext";

type studentcategory = {
    [id: number]: {
        name: string;
    };
};

const AddStudentCategory = () => {
    const { feedback, setFeedback } = useContext(AppContext);
    const [fields, setFields] = useState<studentcategory>({
        0: {
            name: "",
        },
    });

    const handleChange = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        id: number
    ) => {
        setFields({
            ...fields,
            [id]: { ...fields[id], [e.target.name]: e.target.value },
        });
    };

    const handleRow = (variant: string, rowId = 0) => {
        if (variant === "add") {
            const newEntryId =
                parseInt(
                    Object.keys(fields)[Object.entries(fields).length - 1]
                ) + 1;

            setFields({ ...fields, [newEntryId]: { name: "" } });
        } else if (variant === "remove") {
            const updatedStock = Object.fromEntries(
                Object.entries(fields).filter(
                    ([id, stock]) => parseInt(id) !== rowId
                )
            );

            setFields(updatedStock);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setFeedback({ ...feedback, loading: true });

        const customFieldsComponent = {
            category: Object.values(fields),
        };

        const [data, err] = await StudentCategoryService.createStudentCategory(
            customFieldsComponent
        );

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                show: true,
                severity: "success",
            });
        } else {
            setFeedback({
                loading: false,
                message: err,
                show: true,
                severity: "error",
            });
        }
    };

    useEffect(() => {
        return () => {
            setFeedback({
                message: "",
                loading: false,
                severity: "warning",
                show: false,
            });
        };
    }, []);

    return (
        <Box>
            <Grid
                container
                spacing={1.5}
                mt={1.5}
                component="form"
                onSubmit={handleSubmit}
            >
                {feedback.show && (
                    <Grid item xs={12} mb={1.5}>
                        <Alert security={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    </Grid>
                )}

                {Object.entries(fields).map(
                    ([id, field]: any, count: number) => (
                        <Fragment key={id}>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    label="Name"
                                    fullWidth
                                    required
                                    size="small"
                                    name="name"
                                    onChange={(e) => handleChange(e, id)}
                                />
                            </Grid>

                            <Grid item xs={1}>
                                {parseInt(id) === 0 ? (
                                    <IconButton
                                        onClick={() => handleRow("add")}
                                        //   disabled={Object.values(fields).length >= 9}
                                    >
                                        <Add />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() =>
                                            handleRow("remove", parseInt(id))
                                        }
                                    >
                                        <Remove />
                                    </IconButton>
                                )}
                            </Grid>
                        </Fragment>
                    )
                )}

                <Grid item>
                    <Button
                        variant="outlined"
                        type="submit"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1rem" />
                            ) : null
                        }
                    >
                        {feedback.loading
                            ? "adding student category..."
                            : "add student category"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddStudentCategory;
