import { ReceiptLong } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ThemeProvider,
    useTheme,
} from "@mui/material";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CampusService } from "../../../services/CampusService";
import { FamilyService } from "../../../services/FamilyService";
import { FeeCategoryService } from "../../../services/FeeCategoryService";
import { APIRequestStatus, useGetAPI } from "../../../services/Hooks";
import { SessionService } from "../../../services/SessionService";
import { PrintTheme } from "../../../theme/PrintTheme";
import PrintTable from "../../Export/PrintTable";
import { deriveMonthsFromTimeRange } from "../ApplyFees";

const summaryFields = [
    { label: "Total", property: "total" },
    { label: "Discount", property: "discount" },
    { label: "Payable within due date", property: "withinDueDate" },
    { label: "Surcharge", property: "surcharge" },
    { label: "Payable After Due Date", property: "afterDueDate" },
];

export const FamilyReceipt = () => {
    const [familyId, setFamilyId] = useState<string | null>(null);
    const [session, setSession] = useState("");
    const [campus, setCampus] = useState("");
    const [months, setMonths] = useState([]);
    const location = useLocation();

    const [familyReceipt, setFamilyReceipt] = useState<any | null>(null);
    const [receiptStatus, setReceiptStatus] = useState(APIRequestStatus.idle);

    const printRef = useRef<null | any>(null);
    const [printMode, setPrintMode] = useState(false);
    const theme = useTheme();

    const getReceipt = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setReceiptStatus(APIRequestStatus.loading);

        if (
            !familyId ||
            !session ||
            !(categoryStatus === APIRequestStatus.success)
        ) {
            return;
        }

        const [data, err] = await FamilyService.getReceipt({
            family: familyId,
            session,
            months: months,
        });

        if (data) {
            setFamilyReceipt(data.receipt);
            setReceiptStatus(APIRequestStatus.success);
        } else {
            setReceiptStatus(APIRequestStatus.error);
        }
    };

    const [campuses, cc, campusStatus, campusMessage] = useGetAPI(
        CampusService.url,
        1,
        0
    );

    const [sessions, sc, sessionStatus, sessionMessage] = useGetAPI(
        SessionService.url,
        1,
        0,
        { campus },
        [campus],
        [campus]
    );

    const monthsInSession = useMemo(() => {
        if (session) {
            return deriveMonthsFromTimeRange(
                new Date(sessions.find((s: any) => s.id === session).start),
                new Date(sessions.find((s: any) => s.id === session).end)
            );
        } else {
            return [];
        }
    }, [session]);

    const [categories, catC, categoryStatus, categoryMessage] = useGetAPI(
        FeeCategoryService.url,
        1,
        0
    );

    useEffect(() => {
        if (location) {
            const params = new URLSearchParams(location.search);
            const _familyId = params.get("family");
            setFamilyId(_familyId);
        }
    }, [location]);

    return (
        <Grid container spacing={1} component="form" onSubmit={getReceipt}>
            <Grid item xs={12} md={3}>
                <TextField
                    fullWidth
                    select
                    name="campus"
                    label="Campus"
                    value={campus}
                    onChange={(ev) => setCampus(ev.target.value)}
                >
                    {campuses.map((campus: any) => (
                        <MenuItem key={campus.id} value={campus.id}>
                            {campus.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                    fullWidth
                    select
                    name="session"
                    label="Session"
                    value={session}
                    onChange={(ev) => setSession(ev.target.value)}
                >
                    {sessions.map((sess: any) => (
                        <MenuItem key={sess.id} value={sess.id}>
                            {sess.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                    fullWidth
                    label="Months in receipt"
                    select
                    SelectProps={{ multiple: true }}
                    name="months"
                    value={months}
                    onChange={(ev) => setMonths(ev.target.value as any)}
                    // sx={{ overflowX: "auto" }}
                >
                    {monthsInSession.map((month: any) => (
                        <MenuItem
                            key={month.monthNumber}
                            value={month.monthNumber}
                        >{`${month.month}, ${month.year}`}</MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={3}>
                <Button
                    variant="outlined"
                    fullWidth
                    type="submit"
                    sx={{ height: "100%" }}
                    endIcon={
                        receiptStatus === APIRequestStatus.loading ? (
                            <CircularProgress size="1em" />
                        ) : (
                            <ReceiptLong />
                        )
                    }
                >
                    Generate
                </Button>
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="flex-end">
                <PrintTable
                    componentRef={printRef}
                    setPrintMode={setPrintMode}
                />
            </Grid>

            <Grid item xs={12}>
                <ThemeProvider theme={printMode ? PrintTheme : theme}>
                    <TableContainer component={Paper} ref={printRef}>
                        <Table size="small">
                            <TableHead>
                                <TableRow
                                    sx={{ "&>*": { bgcolor: "action.hover" } }}
                                >
                                    <TableCell>Student</TableCell>
                                    <TableCell colSpan={2}>Fees</TableCell>
                                    <TableCell colSpan={2}>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            {familyReceipt && (
                                <TableBody>
                                    {Object.values(familyReceipt).map(
                                        (member: any) => (
                                            <>
                                                <TableRow>
                                                    <TableCell
                                                        rowSpan={Math.max(
                                                            Object.keys(
                                                                member.categories
                                                            ).length,
                                                            5
                                                        )}
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${member.name},&nbsp;Child of,<br />${member.fatherName},<br />${member.classroom}`,
                                                        }}
                                                    ></TableCell>
                                                    <TableCell>
                                                        {Object.keys(
                                                            member.categories
                                                        ).length &&
                                                            categories.find(
                                                                (c: any) =>
                                                                    c.id ==
                                                                    Object.keys(
                                                                        member.categories
                                                                    )[0]
                                                            ).name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Object.values(
                                                            member.categories
                                                        ).length &&
                                                            (Object.values(
                                                                member.categories
                                                            )[0] as any)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {summaryFields[0].label}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            member[
                                                                summaryFields[0]
                                                                    .property
                                                            ]
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                {Object.entries(
                                                    member.categories
                                                )
                                                    .slice(1)
                                                    .map(
                                                        (
                                                            [
                                                                catId,
                                                                catAmount,
                                                            ]: any[],
                                                            idx: number
                                                        ) => (
                                                            <TableRow>
                                                                <TableCell>
                                                                    {
                                                                        categories.find(
                                                                            (
                                                                                c: any
                                                                            ) =>
                                                                                c.id ==
                                                                                catId
                                                                        ).name
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {catAmount}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        summaryFields[
                                                                            idx +
                                                                                1
                                                                        ].label
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        member[
                                                                            summaryFields[
                                                                                idx +
                                                                                    1
                                                                            ]
                                                                                ?.property
                                                                        ]
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                {summaryFields
                                                    .slice(
                                                        Object.keys(
                                                            member.categories
                                                        ).length
                                                    )
                                                    .map((summaryF) => (
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell>
                                                                {summaryF.label}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    member[
                                                                        summaryF
                                                                            .property
                                                                    ]
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={5}
                                                        sx={{
                                                            bgcolor:
                                                                "action.disabled",
                                                        }}
                                                    ></TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    )}
                                    <TableRow>
                                        <TableCell>{`Grand Total: ${Object.values(
                                            familyReceipt
                                        ).reduce(
                                            (prev, rep: any) =>
                                                rep.total + prev,
                                            0
                                        )}`}</TableCell>

                                        <TableCell>{`Discount: ${Object.values(
                                            familyReceipt
                                        ).reduce(
                                            (prev, rep: any) =>
                                                rep.discount + prev,
                                            0
                                        )}`}</TableCell>

                                        <TableCell>{`Total payable within due date: ${Object.values(
                                            familyReceipt
                                        ).reduce(
                                            (prev, rep: any) =>
                                                rep.withinDueDate + prev,
                                            0
                                        )}`}</TableCell>

                                        <TableCell>{`Total surcharge: ${Object.values(
                                            familyReceipt
                                        ).reduce(
                                            (prev, rep: any) =>
                                                rep.surcharge + prev,
                                            0
                                        )}`}</TableCell>

                                        <TableCell>{`Total payable after due date: ${Object.values(
                                            familyReceipt
                                        ).reduce(
                                            (prev, rep: any) =>
                                                rep.afterDueDate + prev,
                                            0
                                        )}`}</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </Grid>
        </Grid>
    );
};
