import { InfoOutlined } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { usePostAPI } from "../../services/Hooks";
import { Autocomplete } from "../AutoComplete";
import { baseAPI } from "../constants";

type TSelectedActor = "" | "students" | "employees" | "classroom";
type Student = { id: number; name: string; baseUser: { id: number } };

const targetType_props: {
    [key: string]: {
        api: string;
        label: string;
        labelKey: string;
        multiple?: boolean;
        processor?: (opt: any) => any;
    };
} = {
    employees: {
        api: "/employee/all-employees",
        label: "Employee",
        labelKey: "name",
        multiple: true,
        processor: (opt: any) => ({
            ...opt,
            name: opt?.baseUser?.name,
            id: opt?.baseUser?.id,
        }),
    },

    students: {
        api: "/org/student",
        label: "Student",
        labelKey: "name",
        multiple: true,
        processor: (opt: any) => ({
            ...opt,
            name: opt?.baseUser?.name,
            id: opt?.baseUser?.id,
        }),
    },

    classroom: {
        api: "/org/academics/classroom",
        label: "Classroom",
        labelKey: "name",
    },
    "": { api: "", label: "Target", labelKey: "" },
};

type CertificateType = "orignal" | "provisional";

type Filter = {
    students: Student[];
    employees: any[];
    classroom: string;
    certificateType: CertificateType;
    certificate: null | { id: number; name: string };
};

type TAssignCertificateProps = { onSuccess: () => void };

const targetTypes = [
    {
        id: 1,
        name: "Employee",
        type: "employees",
    },

    {
        id: 2,
        name: "Student",
        type: "students",
    },

    { id: 3, name: "Classroom", type: "classroom" },
];

const AssignCertificate = ({ onSuccess }: TAssignCertificateProps) => {
    const [selectedActor, setSelectedActor] = useState<TSelectedActor>("");
    const [filter, setFilter] = useState<Filter>({
        students: [],
        employees: [],
        classroom: "",
        certificateType: "orignal",
        certificate: null,
    });
    const theme = useTheme();
    const [showResponseDetails, setShowResponseDetails] = useState(false);

    //
    function handleTarget(selected: any) {
        let updatedFilter = filter;

        switch (selectedActor) {
            case "employees":
                updatedFilter = {
                    ...updatedFilter,
                    employees: selected,
                    students: [],
                    classroom: "",
                };

                break;

            case "students":
                updatedFilter = {
                    ...updatedFilter,
                    employees: [],
                    students: selected,
                    classroom: "",
                };
                break;

            case "classroom":
                updatedFilter = {
                    ...updatedFilter,
                    employees: [],
                    students: [],
                    classroom: selected?.id ?? "",
                };
                break;

            default:
                break;
        }

        setFilter(updatedFilter);
    }

    const [assignCertificate, response, status, message] = usePostAPI(
        `${baseAPI}/certificate/history`
    );

    const handleAssignCertificate = async () => {
        if (!selectedActor) return;

        if (["students", "employees"].includes(selectedActor)) {
            assignCertificate(
                {
                    baseUser: (filter[selectedActor] as any[]).map(
                        (actor) => actor.baseUser.id
                    ),
                    certificate: filter.certificate?.id,
                    issueDate: new Date(),
                    certificateStatus: filter.certificateType,
                },
                {}
            );
        }

        if (selectedActor === "classroom") {
            assignCertificate(
                {
                    baseUser: [],
                    certificate: filter.certificate?.id,
                    clasroom: filter.classroom,
                    issueDate: new Date(),
                    certificateStatus: filter.certificateType,
                },
                {}
            );
        }
    };

    const handleActorSelection = (item: {
        id: number;
        name: string;
        type: typeof selectedActor;
    }) => {
        if (!item) return;

        setSelectedActor(item.type);
        setFilter({
            ...filter,
            [item.type === "employees" ? "students" : "employees"]: [],
        });

        // if (item?.type) {
        // } else {
        //     setFilter({ ...filter, students: [], employees: [], subject: "" });
        // }
    };

    // useEffect(() => {
    //     if (status === "success") {
    //         console.log(response);
    //         onSuccess && onSuccess();
    //     }
    // }, [status]);

    useEffect(() => {
        if (response) console.log(response, status, message);
    }, [response]);

    console.log(response);

    return (
        <Box>
            <Accordion>
                <AccordionSummary>
                    <Typography variant="h5">Assign Certificate</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={2}>
                        {response && (
                            <Grid item xs={12}>
                                <Box>
                                    <Alert
                                        severity="info"
                                        style={{
                                            width: "100%",
                                            marginBottom: "1rem",
                                        }}
                                        action={
                                            <IconButton
                                                onClick={() => {
                                                    setShowResponseDetails(
                                                        !showResponseDetails
                                                    );
                                                }}
                                            >
                                                <InfoOutlined />
                                            </IconButton>
                                        }
                                    >
                                        {`${
                                            response.successes.length
                                        } successes out of ${
                                            response?.successes?.length +
                                            response?.failures?.length
                                        } `}
                                    </Alert>

                                    <Collapse in={showResponseDetails}>
                                        {response.successes?.map(
                                            (
                                                suc: {
                                                    message: string;
                                                    id: number | string;
                                                },
                                                index: number
                                            ) => (
                                                <Alert
                                                    severity="success"
                                                    key={index}
                                                >
                                                    {`ID (${suc.id}) - ${suc.message}`}
                                                </Alert>
                                            )
                                        )}

                                        {response.failures.map(
                                            (
                                                err: {
                                                    message: string;
                                                    id: number | string;
                                                },
                                                index: number
                                            ) => (
                                                <Alert
                                                    severity="error"
                                                    key={index}
                                                >
                                                    {`ID (${err.id}) - ${err.message}`}
                                                    {console.log(
                                                        "this section"
                                                    )}
                                                </Alert>
                                            )
                                        )}
                                    </Collapse>
                                </Box>
                            </Grid>
                        )}

                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                api="/certificate"
                                labelKey="name"
                                label="Certificate"
                                setOutput={(opt) => {
                                    setFilter((f) => ({
                                        ...f,
                                        certificate: opt ?? null,
                                    }));
                                }}
                                textFieldProps={{
                                    variant: "outlined",
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                defaultOptions={targetTypes}
                                labelKey="name"
                                label="Target Type"
                                setOutput={handleActorSelection}
                                textFieldProps={{
                                    variant: "outlined",
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                {...targetType_props[selectedActor]}
                                key={selectedActor}
                                textFieldProps={{
                                    variant: "outlined",
                                    required: true,
                                }}
                                setOutput={(items) => handleTarget(items)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                label="Certificate Type"
                                value={filter.certificateType}
                                onChange={(e) =>
                                    setFilter((f) => ({
                                        ...f,
                                        certificateType: e.target.value as any,
                                    }))
                                }
                            >
                                {[
                                    {
                                        id: 1,
                                        label: "Original",
                                        value: "orignal",
                                    },
                                    {
                                        id: 2,
                                        label: "Provisional",
                                        value: "provisional",
                                    },
                                ].map((opt) => (
                                    <MenuItem key={opt.id} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Button
                                color="primary"
                                fullWidth
                                variant="outlined"
                                onClick={handleAssignCertificate}
                                disabled={status === "loading"}
                                size="large"
                                endIcon={
                                    status === "loading" ? (
                                        <CircularProgress size="1rem" />
                                    ) : null
                                }
                            >
                                {status === "loading"
                                    ? " assigning certificate..."
                                    : "assign certificate"}
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default AssignCertificate;
