import { ArrowDownward, ArrowUpward, ClearAll } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { SectionServices } from "../../../services/SectionServices";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import AddSection from "./AddSection";
import { EditSection } from "./EditSection";

export function Section() {
    const { user } = useContext(AppContext);
    const [filter, setFilter] = useState<any>({
        campus: "",
        classroom: "",
        apply: "",
    });
    const [changes, setChanges] = useState(0);

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({ campus: "", classroom: "", apply: "" });
    };

    // useEffect(() => {
    //     if (filter.classroom === "all") {
    //         setFilter({ ...filter, classroom: undefined });
    //     }
    // }, [filter.classroom]);

    const ChangeSectionSequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await SectionServices.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton onClick={() => handleLevelChange("up")}>
                    <Tooltip title="Move up">
                        <ArrowUpward />
                    </Tooltip>
                </IconButton>

                <IconButton onClick={() => handleLevelChange("down")}>
                    <Tooltip title="Move down">
                        <ArrowDownward />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" color="primary" gutterBottom>
                    Section
                </Typography>

                <MultiPermissionAuthorize ops={["CREATE SECTION"]}>
                    <AddView
                        label="add section"
                        triggerRowsRefetch={() => setChanges(changes + 1)}
                    >
                        <AddSection />
                    </AddView>
                </MultiPermissionAuthorize>
            </div>

            <BaseAccordian>
                <Grid container spacing={1.5}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Classroom"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{ campus: filter.campus }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Apply Filter
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    SectionServices.getSections(page, limit, {
                        // classroom: filter.classroom
                        //     ? filter.classroom
                        //     : undefined,
                        classroom: filter.classroom
                            ? filter.classroom
                            : undefined,

                        search: filter.search ? filter.search : undefined,
                    })
                }
                editor={SectionServices.editSection}
                deletor={
                    user?.baseUser?.ops.includes("DELETE SECTION")
                        ? (id: string | number) =>
                              SectionServices.deleteSectionSoft(id)
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    classroomName: row.classroom.name,
                    educationalCategory: row.classroom.educationalCategory,
                    campusName: row.classroom.campus.name,
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    classroomName: "Classroom",
                    educationalCategory: "Educational Category",
                    campusName: "Campus",
                    Edit: "Edit",
                    Changesequence: "Change Sequence",
                }}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeSectionSequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: user?.baseUser?.ops.includes("UPDATE SECTION")
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE SECTION"]}>
                                <AddView
                                    label="Edit section"
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <EditSection row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, filter.search, changes]}
                ops={{
                    read: "READ SECTION",
                    edit: "",
                    delete: "DELETE SECTION",
                }}
                storageKey="section"
            />
        </div>
    );
}
