import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    TextField,
} from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";
import { SessionService } from "../../../services/SessionService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";

type Props = {
    row?: any;
    updateMode?: boolean;
};

export default function AddSession({ row, updateMode = false }: Props) {
    const [session, setSession] = useState<any>({
        name: "",
        start: "",
        end: "",
        campus: "",
    });

    const { feedback, setFeedback } = useContext(AppContext);
    const [filter, setFilter] = useState({ campus: "" });

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setSession({
            ...session,
            [ev.target.name]: value,
        });
    };

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        if (!updateMode) {
            const [data, err] = await SessionService.createSession({
                ...session,
                name: session.name.trim(),
            });
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        } else {
            const [data, err] = await SessionService.editSession(row.id, {
                ...session,
                name: session.name.trim(),
            });
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        }
    };

    useEffect(() => {
        return () => {
            setSession({ name: "", campus: "", start: "", end: "" });
            setFeedback({
                loading: false,
                message: "",
                severity: "success",
                show: false,
            });
        };
    }, []);

    useEffect(() => {
        if (updateMode) {
            setSession({
                name: row.name,
                start: row.start,
                end: row.end,
                campus: row.campus.id,
            });

            setFilter({ campus: row?.campus.id });
        }
    }, [updateMode]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    {feedback.show && (
                        <Alert severity={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setSession({
                                ...session,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        defaultValue={updateMode && row.campus}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        size="small"
                        label="Name"
                        fullWidth
                        value={session.name}
                        name="name"
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        size="small"
                        label="Start Date"
                        type="date"
                        fullWidth
                        name="start"
                        value={session.start}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        size="small"
                        label="End Date"
                        type="date"
                        fullWidth
                        name="end"
                        value={session.end}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        required
                    />
                </Grid>

                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {!updateMode
                            ? feedback.loading
                                ? "Adding session..."
                                : "Add session"
                            : feedback.loading
                            ? "updating session..."
                            : "update session"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
