import { ShowChart } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  createTheme,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { CampusService } from "../../../services/CampusService";
import { ClassroomService } from "../../../services/ClassroomService";
import { FeeService } from "../../../services/FeeService";
import { APIRequestStatus, useGetAPI } from "../../../services/Hooks";
import { lightThemeOpts } from "../../../theme/Light";
import { AppContext } from "../../AppContext";
import PrintTable from "../../Export/PrintTable";

export const DefaulterReport = () => {
  const [filter, setFilter] = useState<any>({
    campus: "",
    classroom: "",
    threshold: 0,
  });
  const [campuses, setCampuses] = useState<any[]>([]);
  const { user } = useContext(AppContext);
  const [report, setReport] = useState<any>(null);
  const [reportStatus, setReportStatus] = useState(APIRequestStatus.idle);
  const [printMode, setPrintMode] = useState(false);
  const printRef = useRef<any>(null);
  const theme = useTheme();

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, [ev.target.name]: ev.target.value });
  };

  const getCampuses = async () => {
    if (user.campuses?.length) {
      setCampuses(user.campuses);
    } else {
      const [data, err] = await CampusService.getCampus(1, 0);
      if (data) {
        setCampuses(data.rows);
      }
    }
  };

  const getDefaulterReport = async (ev: any) => {
    ev.preventDefault();

    if (filter.classroom && filter.campus) {
      setReportStatus(APIRequestStatus.loading);
      const [data, err] = await FeeService.getDefaulterReport(
        filter.campus,
        filter.classroom,
        filter.threshold
      );

      if (data) {
        setReport(data.report);
        setReportStatus(APIRequestStatus.success);
      } else {
        setReportStatus(APIRequestStatus.error);
      }
    }
  };

  const [classrooms, cc, classroomStatus, cm] = useGetAPI(
    ClassroomService.url,
    1,
    0,
    { campus: filter.campus },
    [filter.campus],
    [filter.campus]
  );

  useEffect(() => {
    if (user) {
      getCampuses();
    }
  }, [user]);

  return (
    <Grid
      container
      spacing={1.25}
      component="form"
      onSubmit={getDefaulterReport}
    >
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          select
          name="campus"
          label="Campus"
          onChange={handleChange}
          value={filter.campus}
        >
          {campuses.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          select
          name="classroom"
          label="Classroom"
          onChange={handleChange}
          value={filter.classroom}
        >
          {classroomStatus === APIRequestStatus.loading && (
            <Box p={2} display="flex" justifyContent="flex-end">
              <CircularProgress size="1em" />
            </Box>
          )}
          {classroomStatus === APIRequestStatus.success &&
            classrooms.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          name="threshold"
          label="Threshold"
          value={filter.threshold}
          onChange={handleChange}
          type="number"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Button
          sx={{ height: "100%" }}
          fullWidth
          variant="outlined"
          endIcon={
            reportStatus === APIRequestStatus.loading ? (
              <CircularProgress size="1em" />
            ) : (
              <ShowChart />
            )
          }
          type="submit"
        >
          Generate Report
        </Button>
      </Grid>

      {report && (
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <PrintTable componentRef={printRef} setPrintMode={setPrintMode} />
        </Grid>
      )}

      {report && (
        <Grid item xs={12}>
          <ThemeProvider
            theme={
              printMode
                ? createTheme({
                    ...lightThemeOpts,
                    palette: {
                      ...lightThemeOpts.palette,
                      background: {
                        ...lightThemeOpts.palette?.background,
                        default: "#fff",
                      },
                    },
                  })
                : theme
            }
          >
            <TableContainer component={Paper} ref={printRef}>
              <Table size="small">
                <TableHead sx={{ "&>*": { bgcolor: "action.hover" } }}>
                  <TableRow>
                    <TableCell>File no.</TableCell>
                    <TableCell>Enroll no.</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Father name</TableCell>
                    <TableCell>Dues</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Alert severity="info">
                          No students in classrom with dues above the given
                          threshold
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )}
                  {report.map((student: any) => (
                    <TableRow key={student.id}>
                      <TableCell>{student.fileNo}</TableCell>
                      <TableCell>{student.enrollmentNo}</TableCell>
                      <TableCell>{student.baseUser.name}</TableCell>
                      <TableCell>{student.fatherName}</TableCell>
                      <TableCell>{student.remaining}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};
