import { Visibility } from "@mui/icons-material";
import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { S3Service } from "../../../services/S3Service";
import { awsDirStruct } from "../../constants";

const CustomFields = ({ row }: any) => {
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);

    const getCustomFieldImages = async () => {
        const campusDir = awsDirStruct.campus.picture.split("/");
        let src: any = [];

        for (const field of row.customFields) {
            if (field.type === "image") {
                const key = `${campusDir[0]}/${field.targetId}/${
                    campusDir[1]
                }/${field.key.replaceAll(" ", "")}`;
                const [data, err] = await S3Service.getS3ByKey(key);

                if (data) {
                    src.push(data.url);
                }
            }
        }

        setImages(src);
    };

    useEffect(() => {
        getCustomFieldImages();
    }, []);

    return (
        <>
            <IconButton
                onClick={() => setOpen(true)}
                disabled={row.customFields.length < 1}
                size="small"
            >
                <Visibility fontSize="small" />
            </IconButton>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Additional Info</DialogTitle>

                <DialogContent>
                    <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                            <Divider>Text</Divider>
                        </Grid>

                        {row.customFields
                            .filter((field: any) => field.type === "text")
                            .map((field: any) => (
                                <Grid item xs={6} key={field.id}>
                                    <TextField
                                        label={field.key}
                                        value={field.value}
                                        InputLabelProps={{
                                            shrink: true,
                                            sx: { textTransform: "capitalize" },
                                        }}
                                        size="small"
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            ))}

                        {row.customFields.filter((f: any) => f.type === "text")
                            .length === 0 && (
                            <Typography
                                textAlign="center"
                                variant="caption"
                                width="100%"
                            >
                                Fields you add will appear here
                            </Typography>
                        )}

                        <Grid item xs={12}>
                            <Divider>Images</Divider>
                        </Grid>

                        {images.map((image: any, id: number) => (
                            <Grid item xs={6} key={id}>
                                <Avatar src={image} variant="square" />
                            </Grid>
                        ))}
                        {row.customFields.filter((f: any) => f.type === "image")
                            .length === 0 && (
                            <Typography
                                textAlign="center"
                                variant="caption"
                                width="100%"
                                color="grey.200"
                            >
                                Fields you add will appear here
                            </Typography>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CustomFields;
