import {
    Add,
    ArrowDownward,
    ArrowUpward,
    ClearAll,
    Edit,
    Visibility,
} from "@mui/icons-material";
import {
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import BaseAccordian from "../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { RoleService } from "../../services/RoleService";
import { AppContext } from "../AppContext";
import { Search } from "../Filters/Search";
import { RUDTable } from "../Table/RUDTable";
import { basePath } from "../constants";
import { ShowSingleRole } from "./ShowSingleRole";

const EditRole = ({ id }: any) => {
    const handleEditRole = () => {
        window.open(`${basePath}/edit-role?role=${id}`);
    };

    return (
        <IconButton onClick={handleEditRole} size="small">
            <Edit fontSize="small" />
        </IconButton>
    );
};

export default function Roles() {
    const { user } = useContext(AppContext);
    const [filter, setFilter] = useState<any>({});
    const [activeRoleId, setActiveRoleId] = useState<number | null>(null);
    const theme = useTheme();

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({ search: "" });
    };

    const [changes, setChanges] = useState(0);

    const ChangeRoleSequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await RoleService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton onClick={() => handleLevelChange("up")}>
                    <Tooltip title="Move up">
                        <ArrowUpward />
                    </Tooltip>
                </IconButton>

                <IconButton onClick={() => handleLevelChange("down")}>
                    <Tooltip title="Move down">
                        <ArrowDownward />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={6}>
                    <Typography
                        variant="h4"
                        color="primary"
                        gutterBottom
                        sx={{ pl: 1.5 }}
                    >
                        Roles
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE ROLE"]}>
                        <Link to="/add-role">
                            <Button
                                startIcon={<Add />}
                                variant="outlined"
                                color="primary"
                                style={{ height: "100%" }}
                            >
                                Add Role
                            </Button>
                        </Link>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid item container spacing={2}>
                    <Grid item xs={10}>
                        {" "}
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    RoleService.getRoles(page, limit, filter)
                }
                // editor={RoleService.editRoles}
                deletor={(id: string | number) =>
                    RoleService.deleteRoleSoft(id)
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    description: row.description || (
                        <Typography color={theme.palette.divider}>
                            N/A
                        </Typography>
                    ),
                })}
                readables={{
                    id: "Id",
                    name: "Name",
                    description: "Description",
                    Changesequence: "Change Sequence",
                    Details: "Details",
                    Edit: "Edit",
                }}
                updatingAgents={[filter, changes]}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeRoleSequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: "Details",
                        content: (row) => (
                            <IconButton
                                onClick={() =>
                                    window.open(
                                        `${basePath}/read-role?role=${row.id}`
                                    )
                                }
                                size="small"
                            >
                                <Visibility fontSize="small" />
                            </IconButton>
                        ),
                    },
                    {
                        header: user?.baseUser?.ops.includes("UPDATE ROLE")
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE ROLE"]}>
                                <EditRole id={row.id} />
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                ops={{
                    read: "READ ROLE",
                    edit: "",
                    delete: "DELETE ROLE",
                }}
                storageKey="role"
            />

            <ShowSingleRole
                activeRoleId={activeRoleId}
                setActiveRoleId={setActiveRoleId}
            />
        </div>
    );
}
