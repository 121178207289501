import { ArrowLeft, ArrowRight, ClearAll, Close } from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Chip,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import {
    ChangeEvent,
    FormEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { CampusService } from "../../../../../services/CampusService";
import { ClassroomService } from "../../../../../services/ClassroomService";
import {
    APIRequestStatus,
    numInWords,
    useGetAPI,
} from "../../../../../services/Hooks";
import { SettingsService } from "../../../../../services/SettingsService";
import { StudentService } from "../../../../../services/StudentService";
import { AppContext } from "../../../../AppContext";
import { Autocomplete } from "../../../../AutoComplete";
import { dateFormatter, formatOrdinals } from "../../../../constants";
import { Search } from "../../../../Filters/Search";
import { RUDTable } from "../../../../Table/RUDTable";

export const WithdrawlRegister = () => {
    const [filter, setFilter] = useState<any>({ search: "" });
    const [report, setReport] = useState<any[]>([]);
    const [sortKey, setSortKey] = useState({ value: "fileNo" });
    const [reportMessage, setReportMessage] = useState("");
    const [campuses, setCampuses] = useState<any[]>([]);
    const { user } = useContext(AppContext);
    const [levels, lc, levelStatus, lm] = useGetAPI(
        ClassroomService.url + "/educational-categories"
    );
    const [intermidiateFilter, setIntermediateFilter] = useState<any>({
        educationalCategory: "",
        ennrollmentNo: "",
        fileNo: "",
        campus: "",
    });
    const [reportStatus, setReportStatus] = useState(APIRequestStatus.idle);
    const theme = useTheme();
    const [printMode, setPrintMode] = useState(false);
    const printRef = useRef<any>(null);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setIntermediateFilter((f: any) => ({
            ...f,
            [ev.target.name]: ev.target.value,
        }));
    };

    const getStudentSortKey = async () => {
        const [data, err] = await SettingsService.getSetting(
            "student-sort-key"
        );

        if (data && data.rows.length > 0) {
            setSortKey(data.rows[0]);
        }
    };

    const getCampuses = async () => {
        const [data, err] = await CampusService.getCampus(1, 0);
        if (data && data.rows.length > 0) {
            setCampuses(data.rows);
        }
    };

    const handleGenerateReport = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setReportStatus(APIRequestStatus.loading);

        const [data, err] = await StudentService.getWithdrawlRegister(
            intermidiateFilter
        );

        if (data) {
            setReport(data.withdrawlRegister);
            setReportStatus(APIRequestStatus.success);
            setReportMessage(data.message);
        } else {
            setReportMessage(err);
            setReportStatus(APIRequestStatus.error);
        }
    };

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setIntermediateFilter({
            educationalCategory: "",
            ennrollmentNo: "",
            fileNo: "",
            campus: "",
        });
        setFilter({ search: "" });
    };

    useEffect(() => {
        getStudentSortKey();
        getCampuses();
    }, []);

    return (
        <Grid container spacing={1}>
            {(reportStatus === APIRequestStatus.success ||
                reportStatus === APIRequestStatus.error) && (
                <Grid item xs={12} mb={1}>
                    <Alert severity={reportStatus as any}>
                        <AlertTitle>Withdrawl Register</AlertTitle>
                        {reportMessage}
                    </Alert>
                </Grid>
            )}
            <Grid
                item
                container
                xs={12}
                spacing={1}
                component="form"
                onSubmit={handleGenerateReport}
            >
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setIntermediateFilter({
                                ...intermidiateFilter,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            required: !intermidiateFilter.campus,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/session"
                        setOutput={(s) =>
                            setIntermediateFilter({
                                ...intermidiateFilter,
                                session: s?.id || "",
                            })
                        }
                        label="Session"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        apiParams={{ campus: intermidiateFilter.campus }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/academics/classroom"
                        setOutput={(classroom) =>
                            setIntermediateFilter({
                                ...intermidiateFilter,
                                classroom: classroom?.id || "",
                            })
                        }
                        label="Classroom"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        apiParams={{ campus: intermidiateFilter.campus }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        select
                        name="educationalCategory"
                        label="Level"
                        fullWidth
                        value={intermidiateFilter.educationalCategory}
                        onChange={handleChange}
                        size="small"
                    >
                        {levels
                            .filter(
                                (level: any) =>
                                    !["graduate", "postgraduate"].includes(
                                        level.category
                                    )
                            )
                            .map((level: any, idx: number) => (
                                <MenuItem key={idx} value={level.category}>
                                    {level.label}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/student"
                        labelKey="name"
                        processor={(student) => ({
                            ...student,
                            name: student.baseUser.name,
                        })}
                        setOutput={(item) => {
                            console.log(item);
                            // setFilter({ ...filter, student: item.id });
                        }}
                        textFieldProps={{ size: "small" }}
                        label="Student"
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        select
                        label="Status"
                        size="small"
                        fullWidth
                        name="status"
                        value={intermidiateFilter.status}
                        onChange={handleChange}
                    >
                        <MenuItem value="admission">Admissioin</MenuItem>
                        <MenuItem value="termination">Termination</MenuItem>
                        <MenuItem value="withdrawl">Withdrawl</MenuItem>
                    </TextField>
                </Grid>

                {/*                     
                    <TextField
                        name={sortKey.value}
                        label={
                            sortKey.value === "fileNo"
                                ? "File no."
                                : "Enroll no."
                        }
                        fullWidth
                        value={intermidiateFilter[sortKey.value]}
                        onChange={handleChange}
                        size="small"
                    /> */}
            </Grid>
            <Grid item xs={12} md={4}>
                <Search
                    filter={filter}
                    setFilter={setFilter}
                    clear={clearFilters}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Tooltip title="Clear Filters">
                    <IconButton onClick={handleFilterClear} color="primary">
                        <ClearAll />
                    </IconButton>
                </Tooltip>
            </Grid>

            {intermidiateFilter.campus ? (
                <Grid item xs={12}>
                    <RUDTable
                        getter={(page, limit) =>
                            StudentService.getWithdrawlRegister({
                                page,
                                limit,
                                ...intermidiateFilter,
                                search: filter.search ? filter.search : "",
                            })
                        }
                        editor={function (...args: any): Promise<any[]> {
                            throw new Error("Function not implemented.");
                        }}
                        rowsPreprocessor={(row) => ({
                            ...row,
                            admissionDate: dateFormatter.format(
                                new Date(row.admissionDate)
                            ),
                            withdrawlDate:
                                row.withdrawlDate == "-"
                                    ? "-"
                                    : new Date(
                                          row.withdrawlDate
                                      ).toDateString(),
                            type: (
                                <Chip
                                    label={row.type}
                                    size="small"
                                    variant="outlined"
                                    sx={{ textTransform: "capitalize" }}
                                    icon={
                                        row.type === "admission" ? (
                                            <ArrowRight />
                                        ) : row.type === "termination" ? (
                                            <Close />
                                        ) : (
                                            <ArrowLeft />
                                        )
                                    }
                                    color={
                                        row.type === "admission"
                                            ? "success"
                                            : row.type === "termination"
                                            ? "error"
                                            : "primary"
                                    }
                                />
                            ),
                            dob: (
                                <Typography>
                                    {`${formatOrdinals(
                                        parseInt(row.dob.split("-")[2])
                                    )} ${new Date(
                                        1,
                                        parseInt(row.dob.split("-")[1]) - 1,
                                        3
                                    ).toLocaleString("default", {
                                        month: "long",
                                    })}, ${numInWords(
                                        row.dob.split("-")[0]
                                    ).toLocaleLowerCase()}`}

                                    <br />

                                    {new Date(row.dob).toLocaleDateString()}
                                </Typography>
                            ),
                            classAdmittedIn: row.classroom.name,
                            classWithdrawnFrom: row?.prevClassroom?.name ?? "-",
                            reason: row.reason,
                            remarks: row.remarks,
                            slc: row.slc,
                            coCarriculumActivities: row.coCarriculumActivities,
                        })}
                        readables={{
                            admissionDate: "Date of Admission",
                            name: "Student Name",
                            dob: "DOB in words & figures",
                            fatherName: "Father Name",
                            residence: "Residence",
                            classAdmittedIn: "Class to which admitted",
                            arrears: "arrears",
                            classWithdrawnFrom: "Class from which withdrawn",
                            withdrawlDate: "Date of withdrawl",
                            fileNo: "File No.",
                            type: "Entry Type",
                            reason: "Withdrawl Reason",
                            remarks: "Remarks",
                            slc: "Slc",
                            coCarriculumActivities: "coCarriculumActivities",
                            prePrimaryNo: "prePrimaryNo",
                            primaryNo: "primaryNo",
                            middleNo: "middleNo",
                            highNo: "highNo",
                            admissionNo: "Admission No.",
                        }}
                        ops={{ read: "", edit: "", delete: "" }}
                        updatingAgents={[intermidiateFilter, filter.search]}
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Alert severity="info">
                        Please select a campus to generate report
                    </Alert>
                </Grid>
            )}
            {/* admissionDate name dob dob residence withdrawlDate fileNo */}

            {/* 
            
            */}

            {/* <Grid item xs={12} display="flex" justifyContent="flex-end">
                <PrintTable
                    componentRef={printRef}
                    setPrintMode={setPrintMode}
                />
            </Grid>

            <Grid item xs={12}>
                <ThemeProvider theme={printMode ? PrintTheme : theme}>
                    <TableContainer component={Paper} ref={printRef}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ whiteSpace: "nowrap" }}>
                                    <TableCell>Date of admission</TableCell>
                                    <TableCell>Sr. no</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>
                                        Date&nbsp;of&nbsp;birth&nbsp;in
                                        words&nbsp;and&nbsp;figures
                                    </TableCell>
                                    <TableCell>Father Name</TableCell>
                                    <TableCell>Caste or tribe</TableCell>
                                    <TableCell>Father Occupation</TableCell>
                                    <TableCell>Residence</TableCell>
                                    <TableCell>
                                        Class to which admitted
                                    </TableCell>
                                    <TableCell>Arrears due</TableCell>
                                    <TableCell>
                                        Class from which withdrawn
                                    </TableCell>
                                    <TableCell>Date of withdrawl</TableCell>
                                    <TableCell>File no.</TableCell>
                                    <TableCell>Remarks</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {report &&
                                    report.map((student: any, idx: number) => (
                                        <TableRow key={idx}>
                                            <TableCell>
                                                {dateFormatter.format(
                                                    new Date(
                                                        student.admissionDate
                                                    )
                                                )}
                                            </TableCell>
                                            <TableCell>{idx + 1}</TableCell>
                                            <TableCell>
                                                {student.name}
                                            </TableCell>
                                            <TableCell>
                                                {`${formatOrdinals(
                                                    parseInt(
                                                        student.dob.split(
                                                            "-"
                                                        )[2]
                                                    )
                                                )} ${new Date(
                                                    1,
                                                    parseInt(
                                                        student.dob.split(
                                                            "-"
                                                        )[1]
                                                    ) - 1,
                                                    3
                                                ).toLocaleString("default", {
                                                    month: "long",
                                                })}, ${numInWords(
                                                    student.dob.split("-")[0]
                                                )}`.toLocaleLowerCase()}

                                                <br />

                                                {new Date(
                                                    student.dob
                                                ).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell>
                                                {student.fatherName}
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                {student.residence}
                                            </TableCell>
                                            <TableCell>
                                                {student.firstClassroom?.name ??
                                                    "-"}
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    variant="outlined"
                                                    color={
                                                        !student.arrears
                                                            ? "success"
                                                            : "warning"
                                                    }
                                                    label={currencyFormatter(
                                                        student.arrears
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {student.lastClassroom?.name ??
                                                    "-"}
                                            </TableCell>
                                            <TableCell>
                                                {student.withdrawlDate}
                                            </TableCell>
                                            <TableCell>
                                                {student.fileNo}
                                            </TableCell>
                                            <TableCell
                                                sx={{ minWidth: "30ch" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: student.withdrawlRemarks?.replaceAll(
                                                        "\n",
                                                        "<br />"
                                                    ),
                                                }}
                                            ></TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </Grid> */}
        </Grid>
    );
};

// {
//     "name": "Ibrar",
//     "admissionDate": "2023-10-24T00:00:00.000Z",
//     "dob": "2023-10-01T00:00:00.000Z",
//     "fatherName": "Hammad",
//     "fileNo": "1",
//     "residence": "asfsdf",
//     "arrears": 0,
//     "firstClassroom": {
//         "name": "Play Group - (PG)",
//         "id": 1
//     },
//     "lastClassroom": {
//         "name": "Play Group - (PG)",
//         "id": 1
//     }
// }
