import { Box } from "@material-ui/core";
import { InfoOutlined, Upgrade } from "@mui/icons-material";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { CampusService } from "../../../services/CampusService";
import { ClassroomService } from "../../../services/ClassroomService";
import {
    APIRequestStatus,
    useGetAPI,
    usePostAPI,
} from "../../../services/Hooks";
import { SectionServices } from "../../../services/SectionServices";
import { SessionService } from "../../../services/SessionService";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { baseAPI, notConnectedMessage } from "../../constants";

const studentCols = [
    "File no.",
    "Enroll no.",
    "Name",
    "Father name",
    "Classroom",
];

export const DeletePromotion = () => {
    const theme = useTheme();
    const [status, setStatus] = useState<APIRequestStatus>(
        APIRequestStatus.idle
    );
    const [showResponseDetails, setShowResponseDetails] = useState(false);
    const [message, setMessage] = useState(notConnectedMessage);
    const [response, setResponse] = useState(null);
    const { user } = useContext(AppContext);

    const [pagination, setPagination] = useState({
        page: 0,
        limit: 0,
        count: 0,
    });

    const [filterData, setFilterData] = useState<any>({
        _toSession: [],
    });
    const [campuses, setCampuses] = useState<any[]>([]);
    const [selected, setSelected] = useState<any>({
        campus: "",
        fromSession: "",
        fromClassroom: "",
        fromSection: "",
        toCampus: "",
        toSession: "",
        toClassroom: "",
        toSection: "",
        exclude: [],
    });

    const getCampuses = async () => {
        const [data, err] = await CampusService.getCampus(1, 0);
        if (data) {
            setCampuses(data.rows);
        }
    };

    useEffect(() => {
        getCampuses();
    }, []);

    const [classrooms, _, classroomStatus, _m] = useGetAPI(
        ClassroomService.url,
        1,
        0,
        { campus: selected.campus },
        [selected.campus],
        [selected.campus]
    );

    const [sessions, ____, sessionStatus, ____m] = useGetAPI(
        SessionService.url,
        1,
        0,
        { campus: selected.campus },
        [selected.campus],
        [selected.campus]
    );

    const [fromSections, __, fromSectionsStatus, __m] = useGetAPI(
        SectionServices.url,
        1,
        0,
        { classroom: selected.fromClassroom },
        [selected.fromClassroom],
        [selected.fromClassroom]
    );

    const [toSections, ___, toSectionsStatus, ___m] = useGetAPI(
        SectionServices.url,
        1,
        0,
        { classroom: selected.toClassroom },
        [selected.toClassroom],
        [selected.toClassroom]
    );

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setSelected({ ...selected, [ev.target.name]: ev.target.value });
    };

    const [
        changedCampus,
        promotionResponse,
        promotionStatus,
        promotionMessage,
    ] = usePostAPI(baseAPI + "/org/student/restore-promotion-bulk");

    const handlePromoteSection = async () => {
        changedCampus(
            {
                session: selected.fromSession,
                classroom: selected.fromClassroom,
                section: selected.fromSection,
                ids: selected.exclude,
            },
            {}
        );
    };

    console.log(promotionResponse);

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        const [data, err] = await StudentService.changeCampusBulk(
            selected.exclude,
            {
                campus: selected.toCampus,
                session: selected.toSession,
                classroom: selected.toClassroom,
                section: selected.toSection,
            }
        );
    };

    const [students, studentCount, studentStatus, studentMessage] = useGetAPI(
        StudentService.url,
        pagination.page + 1,
        pagination.limit,
        { classroom: selected.fromClassroom, section: selected.fromSection },
        [selected.fromClassroom, selected.fromSection],
        [selected.fromClassroom, selected.fromSection]
    );

    const handleCheckChange = (type: "single" | "all", id?: number) => {
        if (type === "single") {
            if (selected.exclude.includes(id)) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: selected.exclude.filter(
                        (_id: number) => _id !== id
                    ),
                }));
            } else {
                setSelected((s: any) => ({
                    ...s,
                    exclude: [...s.exclude, id],
                }));
            }
        } else {
            if (selected.exclude.length === 0) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: students.map((std: any) => std.id),
                }));
            } else {
                setSelected((s: any) => ({ ...s, exclude: [] }));
            }
        }
    };

    useEffect(() => {
        setPagination((_p) => ({ ..._p, count: studentCount }));
    }, [studentCount]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {(promotionStatus === APIRequestStatus.success ||
                    promotionStatus === APIRequestStatus.error) && (
                    <Alert
                        severity={
                            promotionResponse?.errors?.length
                                ? "error"
                                : "success"
                        }
                        action={
                            <IconButton
                                onClick={() => {
                                    setShowResponseDetails(
                                        !showResponseDetails
                                    );
                                }}
                            >
                                <InfoOutlined fontSize="small" />
                            </IconButton>
                        }
                    >
                        {promotionMessage}
                    </Alert>
                )}

                <Collapse in={showResponseDetails}>
                    {promotionResponse &&
                        promotionResponse.successes?.map(
                            (
                                suc: {
                                    message: string;
                                    id: number | string;
                                },
                                index: number
                            ) => (
                                <Box
                                    key={index}
                                    bgcolor={"#0c130d"}
                                    style={{
                                        display: "flex",
                                        gap: "2rem",
                                        paddingLeft: "2rem",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <Typography variant="caption">
                                        {`ID (${suc.id}) - ${suc.message}`}
                                    </Typography>
                                </Box>
                            )
                        )}

                    {promotionResponse &&
                        promotionResponse.errors.map(
                            (
                                err: {
                                    message: string;
                                    id: number | string;
                                },
                                index: number
                            ) => (
                                <Box
                                    key={index}
                                    bgcolor={"#160b0b"}
                                    style={{
                                        display: "flex",
                                        gap: "2rem",
                                        paddingLeft: "2rem",
                                    }}
                                >
                                    <Typography variant="caption">
                                        {`ID (${err.id}) - ${err.message}`}
                                    </Typography>
                                </Box>
                            )
                        )}
                </Collapse>
            </Grid>
            <Grid item xs={12} md={3}>
                <Autocomplete
                    api="/org/campus"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            campus: c?.id || "",
                        })
                    }
                    label="Campus"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSession: c?.id || "",
                        })
                    }
                    label="Session"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus }}
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <Autocomplete
                    api="/org/academics/classroom"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromClassroom: c?.id || "",
                        })
                    }
                    label="Classroom"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus }}
                />
            </Grid>

            <Grid item xs={12} md={3} sx={{ mb: "2rem" }}>
                <Autocomplete
                    api="/org/academics/section"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSection: c?.id || "",
                        })
                    }
                    label="Section"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ classroom: selected.fromClassroom }}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ mb: "2rem" }}> SELECT </Divider>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow
                                sx={{ "&>*": { bgcolor: "action.hover" } }}
                            >
                                {studentStatus === APIRequestStatus.loading && (
                                    <TableCell colSpan={6}>
                                        <LinearProgress />
                                    </TableCell>
                                )}
                                {studentStatus === APIRequestStatus.error && (
                                    <TableCell colSpan={6}>
                                        <Alert severity="error">
                                            {studentMessage}
                                        </Alert>
                                    </TableCell>
                                )}
                                <TableCell>
                                    <Checkbox
                                        checked={
                                            selected.exclude.length ===
                                            students.length
                                        }
                                        onChange={() =>
                                            handleCheckChange("all")
                                        }
                                        indeterminate={
                                            selected.exclude.length > 0 &&
                                            selected.exclude.length <
                                                studentCount
                                        }
                                    />
                                </TableCell>
                                {studentCols.map((col) => (
                                    <TableCell key={col}>{col}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {studentStatus === APIRequestStatus.success &&
                                studentCount === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Alert severity="info">
                                                No students in classroom
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )}
                            {studentStatus === APIRequestStatus.success &&
                                students.map((student: any) => (
                                    <TableRow key={student.id}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selected.exclude.includes(
                                                    student.id
                                                )}
                                                onChange={() =>
                                                    handleCheckChange(
                                                        "single",
                                                        student.id
                                                    )
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>{student.fileNo}</TableCell>
                                        <TableCell>
                                            {student.enrollmentNo}
                                        </TableCell>
                                        <TableCell>
                                            {student.baseUser.name}
                                        </TableCell>
                                        <TableCell>
                                            {student.fatherName}
                                        </TableCell>
                                        <TableCell>{`${student.classroom.name} (${student.section.name}), ${student.session.name}`}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    fullWidth
                    type={"submit"}
                    onClick={handlePromoteSection}
                    sx={{ height: "100%" }}
                    disabled={promotionStatus === APIRequestStatus.loading}
                    endIcon={
                        promotionStatus === APIRequestStatus.loading ? (
                            <CircularProgress size="1em" />
                        ) : (
                            <Upgrade />
                        )
                    }
                >
                    {promotionStatus === APIRequestStatus.loading
                        ? "Deleting  Student Promotions..."
                        : "Delete Student Promotion"}
                </Button>
            </Grid>
        </Grid>
    );
};
