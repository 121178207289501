import { ArrowDownward, ArrowUpward, Check, Close } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import {
    Checkbox,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import BaseAccordian from "../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { FeeCategoryService } from "../../services/FeeCategoryService";
import AddView from "../AddView";
import { AppContext } from "../AppContext";
import SearchBar from "../Filters/SearchBar";
import { RUDTable } from "../Table/RUDTable";
import { AddFeeCategory } from "./AddFeeCategory";

export const FeeCategory = () => {
    const [filter, setFilter] = useState({
        search: "",
        optional: false,
        editable: false,
    });
    const [feeCategory, setFeeCategory] = useState<any>(null);
    const [changes, setChanges] = useState(0);
    const { user } = useContext(AppContext);

    const theme = useTheme();

    const EditFeeCategory = (row: any) => {
        setFeeCategory(row);
    };

    const ChangeFeeCategorySequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await FeeCategoryService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton onClick={() => handleLevelChange("up")}>
                    <Tooltip title="Move up">
                        <ArrowUpward />
                    </Tooltip>
                </IconButton>

                <IconButton onClick={() => handleLevelChange("down")}>
                    <Tooltip title="Move down">
                        <ArrowDownward />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography
                        variant="h4"
                        color="primary"
                        gutterBottom
                        sx={{ pl: 1.5 }}
                    >
                        Fee Category
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE FEE_CATEGORY"]}>
                        <AddView
                            label="add fee category"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddFeeCategory />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid container spacing={1}>
                    <Grid item lg={4}>
                        <SearchBar
                            setOutput={(query) =>
                                setFilter({ ...filter, search: query })
                            }
                            debounceChange
                        />
                    </Grid>

                    <Grid item xs="auto" container gap={1}>
                        <Grid
                            item
                            sx={{
                                px: 1,
                                borderRadius: theme.spacing(0.5),
                                border: `1px solid ${theme.palette.divider}`,
                            }}
                        >
                            <FormControlLabel
                                label="Optional"
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={filter.optional}
                                        onChange={(e, check) =>
                                            setFilter({
                                                ...filter,
                                                optional: check,
                                            })
                                        }
                                    />
                                }
                            />
                        </Grid>

                        <Grid
                            item
                            sx={{
                                px: 1,
                                borderRadius: theme.spacing(0.5),
                                border: `1px solid ${theme.palette.divider}`,
                            }}
                        >
                            <FormControlLabel
                                label="Editable"
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={filter.editable}
                                        onChange={(e, check) =>
                                            setFilter({
                                                ...filter,
                                                editable: check,
                                            })
                                        }
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    FeeCategoryService.getFeeCategory(
                        page,
                        limit,
                        Object.fromEntries(
                            Object.entries(filter).filter(([key, value]) =>
                                Boolean(value)
                            )
                        )
                    )
                }
                editor={FeeCategoryService.editFeeCategory}
                deletor={
                    // (id: string | number) =>
                    //   FeeCategoryService.deleteFeeCategorySoft(id)
                    user?.baseUser?.ops.includes("DELETE FEE_CATEGORY")
                        ? (id: string | number) =>
                              FeeCategoryService.deleteFeeCategorySoft(id)
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    //in here we will map nested values
                    deletionId: row.id,
                    optional: row.optional ? <Check /> : <Close />,
                    editable: row.editable ? <Check /> : <Close />,

                    status: (
                        <Chip
                            sx={{ minWidth: "65px" }}
                            size="small"
                            variant="outlined"
                            label={
                                row.status === "active" ? "Active" : "Inactive"
                            }
                            color={
                                row.status === "active" ? "success" : "error"
                            }
                        />
                    ),
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    optional: "Optional",
                    editable: "Editable",
                    Edit: "Edit",
                    Changesequence: "Change Sequence",
                    status: "Status",
                }}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeFeeCategorySequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: user?.baseUser?.ops.includes(
                            "UPDATE FEE_CATEGORY"
                        )
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["UPDATE FEE_CATEGORY"]}
                            >
                                <IconButton
                                    onClick={() => EditFeeCategory(row)}
                                >
                                    <Edit />
                                </IconButton>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter, changes]}
                ops={{
                    read: "READ FEE_CATEGORY",
                    edit: "",
                    delete: "DELETE FEE_CATEGORY",
                }}
                storageKey="feeCategory"
            />

            <Dialog
                open={feeCategory !== null}
                onClose={() => {
                    setFeeCategory(null);
                    setChanges(changes + 1);
                }}
            >
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Grid item>Edit Fee Category</Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <AddFeeCategory updateMode _feeCategory={feeCategory} />
                </DialogContent>
            </Dialog>
        </>
    );
};
